import { List, Typography, Space, Row, Col, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import BorderedSection from 'components/organsims/BorderedSection';
import ProductPreview from 'components/molecules/ProductPreview';
import { useTranslation } from 'react-i18next';
import OrderDetails from './OrderDetails';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cart from 'redux/cart';
import { fetchOrderById } from 'redux/orders/thunks';
import Orders from 'redux/orders';
import { useHistory } from 'react-router';
import Payments from 'redux/payments';

const renderItem = (product) => (
  <div className="product-preview">
    <ProductPreview productOrder={product} />
  </div>
);

const PaymentSuccess = () => {
  const { t } = useTranslation('screens');
  const { t: globalTranslate } = useTranslation('footer');

  const dispatch = useDispatch();
  const order_id = useSelector((state) => state.payments.order_id);
  const [orderResult, setOrderResult] = useState();
  const order = useSelector((state) => Orders.selectors.selectById(state, orderResult));
  const products = useSelector(Orders.selectors.selectOrderProducts)(orderResult);
  const history = useHistory();

  const onTrackOrder = useCallback(() => {
    history.push('/profile/orders');
  }, [history]);

  useEffect(() => {
    if (!order && !order_id) onTrackOrder();
  }, [onTrackOrder, order, order_id]);

  useEffect(() => {
    //remove cart and order_id
    if (order_id)
      dispatch(fetchOrderById({ id: order_id }))
        .then((response) => {
          setOrderResult(response?.payload?.result);
          dispatch(Cart.slice.actions.resetCart());
          dispatch(Payments.slice.actions.resetPayment());
        })
        .catch((error) => console.log(error));
  }, [dispatch, order_id]);

  return (
    <BorderedSection
      title={t('payment_success.title')}
      description={t('payment_success.description')}
      type="success"
      extraIconTitle={<CheckCircleOutlined />}
    >
      <Space direction="vertical">
        <OrderDetails
          title={t('payment_success.order_details.number')}
          details={<Typography.Text>{order?.id}</Typography.Text>}
        />
        <OrderDetails
          title={t('payment_success.order_details.payment_method')}
          details={<Typography.Text>{order?.payment_method}</Typography.Text>}
        />
        <OrderDetails
          title={t('payment_success.order_details.products')}
          details={<List dataSource={products} renderItem={renderItem} />}
        />
        <OrderDetails
          title={t('payment_success.order_details.total')}
          details={<Typography.Text>{order?.checkout_total_price}</Typography.Text>}
        />
        {/* removed for now */}
        {/* <OrderDetails
          title={t('payment_success.order_details.delivery_date')}
          details={<Typography.Text>{order?.delivery_date}</Typography.Text>}
        /> */}
        <OrderDetails
          title={t('payment_success.order_details.contact')}
          details={<Typography.Text>{globalTranslate('hotline-number')}</Typography.Text>}
        />
        <Row>
          <Col xs={24} sm={24} m={12} lg={12} xl={12} xxl={12} className="button-wrapper">
            <Button type="primary" onClick={onTrackOrder}>
              {t('payment_success.order_details.track')}
            </Button>
          </Col>
        </Row>
      </Space>
    </BorderedSection>
  );
};
export default PaymentSuccess;
