import Icon from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import './index.scss';

const Perks = ({ image, title, description }) => {
  return (
    <Row className="perk-wrapper">
      <Space>
        <Col>
          <Icon component={image} />
        </Col>
        <Col>
          <Row>
            <Typography.Text strong type="secondary">
              {title}
            </Typography.Text>
          </Row>
          <Row>
            <Typography.Text type="secondary">{description}</Typography.Text>
          </Row>
        </Col>
      </Space>
    </Row>
  );
};
export default Perks;
