import { useCallback, useState } from 'react';
import { Button, Col, Modal, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import DetailsItem from '../../../screens/Profile/components/DetailsItem';
import { useHistory } from 'react-router-dom';
import Addresses from 'redux/addresses';
import { useSelector, useDispatch } from 'react-redux';
import { deleteAddress } from 'redux/addresses/thunks';
import './index.scss';
import { capitalizeString } from 'utils/stringsHelper';

const AddressItem = ({ addressId, isEditable, onPickAddress }) => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const dispatch = useDispatch();
  const address = useSelector((state) => Addresses.selectors.selectById(state, addressId));
  const user = useSelector((store) => store.auth.currentUser);
  const [loading, setLoading] = useState(false);

  const onEditAddress = useCallback(() => {
    history.push(`/address/${addressId}`);
  }, [addressId, history]);

  const onChooseAddress = () => {
    if (onPickAddress && !isEditable) {
      setLoading(true);
      onPickAddress(addressId);
    }
  };

  const onDeleteAddress = useCallback(() => {
    Modal.confirm({
      title: t('profile.addresses.delete_confirm'),
      onOk() {
        dispatch(deleteAddress({ id: addressId }));
      },
    });
  }, [addressId, dispatch, t]);

  return (
    <Row className="address-item-wrapper">
      <Col xs={24} sm={8} m={8} lg={8} xl={10}>
        <DetailsItem title={t('profile.addresses.name')} value={user.full_name} />
        <DetailsItem title={t('profile.addresses.street')} value={`${address.building} ${address.street_name}`} />
        <DetailsItem title={t('profile.addresses.district')} value={address.district} />
        <DetailsItem title={t('profile.addresses.city')} value={address.governorate} />
      </Col>
      <Col xs={24} sm={8} m={8} lg={8} xl={10}>
        <DetailsItem title={t('profile.addresses.mobile')} value={user.phone_number} />
        <DetailsItem title={t('profile.addresses.mobile_type')} value={capitalizeString(address.number_type)} />
        <DetailsItem
          title={t('profile.addresses.address_number')}
          value={`${address.city_code || ''}${address.phone_number || ''}`}
        />
        <DetailsItem title={t('profile.addresses.info')} value={address.instructions} />
      </Col>
      <Space align="center" className="address-action-btn">
        <Col xs={24} sm={4} m={4} lg={4} xl={2}>
          <Button type="primary" onClick={isEditable ? onEditAddress : onChooseAddress} loading={loading}>
            {isEditable ? t('profile.addresses.edit') : t('profile.addresses.choose')}
          </Button>
        </Col>
        <Col xs={24} sm={4} m={4} lg={4} xl={2}>
          {isEditable && (
            <Button className="delete-button" onClick={onDeleteAddress}>
              {t('profile.addresses.delete')}
            </Button>
          )}
        </Col>
      </Space>
    </Row>
  );
};
export default AddressItem;
