import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from 'redux/cart/thunks';

const AddToCart = ({ id, isAvailable }) => {
  const dispatch = useDispatch();
  const cart = useSelector((store) => store.cart);
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('atoms');

  useEffect(() => {
    setIsAlreadyAdded(cart.ids.includes(id));
  }, [cart, id]);

  const handleError = (error) => {
    Modal.error({
      content: (
        <>
          {error?.errors?.map((errorItem) => (
            <Typography.Text>{errorItem}</Typography.Text>
          ))}
        </>
      ),
    });
  };

  const onPress = () => {
    if (isAlreadyAdded) {
      setLoading(true);
      dispatch(removeFromCart({ order_product: { product_id: id, _destroy: 'true' } }))
        .then(unwrapResult)
        .catch(handleError)
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      dispatch(addToCart({ order_product: { product_id: id, quantity: 1, _destroy: 'false' } }))
        .then(unwrapResult)
        .catch(handleError)
        .finally(() => setLoading(false));
    }
  };

  return (
    <Button type={isAlreadyAdded ? 'default' : 'primary'} onClick={onPress} loading={loading} disabled={!isAvailable}>
      {!isAlreadyAdded ? t('add_to_cart.add') : t('add_to_cart.remove')}
    </Button>
  );
};

export default AddToCart;
