import { useTranslation } from 'react-i18next';
import { useCallback, Fragment } from 'react';
import { PhoneFilled } from '@ant-design/icons';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Space from 'antd/es/space';
import Logo from 'assets/images/Abdelaziz-stores-logo.jpg';
import { Typography } from 'antd';
import { FacebookFilled, InstagramFilled, YoutubeFilled, LinkedinFilled } from '@ant-design/icons';
import { RiWhatsappFill } from 'react-icons/ri';

import { isSignedInSelector } from 'redux/auth/selectors';
import promoImage from 'assets/images/Electronic.png';
import Brands from 'redux/brands';
import './index.scss';

const MAX_DISPLAYED_BRANDS = 5;

const Footer = () => {
  const { t, i18n } = useTranslation('footer');

  const isSignedIn = useSelector(isSignedInSelector);
  const featuredBrands = useSelector(Brands.selectors.getFeaturedBrands);

  const location = useLocation();
  const history = useHistory();

  const onBrandPress = useCallback(
    (brand_id) => () => {
      const brand = queryString.stringify({ brand_id });
      location.search = brand;
      history.push('/products?' + location.search);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    [history, location],
  );

  const brandsRender = (brand) => (
    <Button type="text" onClick={onBrandPress(brand.id)} key={`button_category_${brand.id}`}>
      {brand.name}
    </Button>
  );

  return (
    <div className="site-footer">
      {location.pathname === '/' && (
        <div className="promo-section">
          <div>
            <Typography.Title strong>{t('promo-section.title')}</Typography.Title>
            <Typography.Text>{t('promo-section.description')}</Typography.Text>
          </div>
          <img src={promoImage} alt="promoImage" />
        </div>
      )}
      <div className="site-footer-header">
        <Row>
          <Col xs={24} sm={24} md={0} lg={6}>
            <img src={Logo} alt="logo" className="footer-logo" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Space direction="vertical">
              {featuredBrands.slice(0, MAX_DISPLAYED_BRANDS).map(brandsRender)}
              <Link to="/special-offers">
                <Button type="text">{t('special')}</Button>
              </Link>
            </Space>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Space direction="vertical">
              {!isSignedIn && (
                <Fragment>
                  <Link to="/auth/signin">
                    <Button type="text">{t('sign-in')}</Button>
                  </Link>
                  <Link to="/auth/signup">
                    <Button type="text">{t('register')}</Button>
                  </Link>
                </Fragment>
              )}
              <Link to="/terms-conditions">
                <Button type="text"> {t('exchange&refunds')}</Button>
              </Link>
            </Space>
          </Col>
        </Row>
      </div>
      <div className="site-footer-bottom">
        <Row align="center" justify="space-between" className="site-footer-bottom-wrapper">
          <Col sm={24} md={16} lg={12} xl={12}>
            <Typography.Text>
              <Space>
                <PhoneFilled rotate={i18n.dir() === 'rtl' ? 0 : 90} />
                <Typography.Text>
                  <Space size="small" wrap>
                    {`${t('hotline')}:`}
                    <Button target="_blank" type="primary" href="tel:16581" className="number-buttons">
                      {t('hotline-number')}
                    </Button>
                    {t('or')}
                    <Button target="_blank" type="primary" href="tel:01120777715" className="number-buttons">
                      {t('phone2')}
                    </Button>
                    {t('or')}
                    <Button target="_blank" type="primary" href="tel:01120777716" className="number-buttons">
                      {t('phone1')}
                    </Button>
                  </Space>
                </Typography.Text>
              </Space>
            </Typography.Text>
          </Col>
          <Col sm={24} md={8} lg={12} xl={12}>
            <Row className="" align="center" justify="end">
              <Space size="small">
                <Col>
                  <Button
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=201120777715"
                    type="primary"
                    icon={<RiWhatsappFill size={18} className="custom-whatsapp-button" />}
                  />
                </Col>
                <Col>
                  <Button
                    target="_blank"
                    href="https://www.facebook.com/Abdulazizstores/"
                    type="primary"
                    icon={<FacebookFilled />}
                  />
                </Col>
                <Col>
                  <Button
                    target="_blank"
                    href="https://www.instagram.com/abdulazizstores/"
                    type="primary"
                    icon={<InstagramFilled />}
                  />
                </Col>
                <Col>
                  <Button
                    target="_blank"
                    href="https://www.youtube.com/channel/UCRd2YBDJG5Sq8zeg57jj22w"
                    type="primary"
                    icon={<YoutubeFilled />}
                  />
                </Col>
                {/* <Col>
                  <Button target="_blank" href="https://www.linkedin.com" type="primary" icon={<LinkedinFilled />} />
                </Col> */}
              </Space>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
