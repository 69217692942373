import Typography from 'antd/es/typography';
import Breadcrumb from 'antd/es/breadcrumb';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'querystring';
import Categories from 'redux/categories';
import isEmpty from 'lodash/isEmpty';
import './index.scss';

const Breadcrumbs = () => {
  const { search, path } = useLocation();
  const { t } = useTranslation('routes');
  const params = queryString.parse(search.substring(1));
  const category = useSelector(Categories.selectors.categorySearch)(parseInt(params?.category_id));

  let breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">{t('home')}</Link>
    </Breadcrumb.Item>,
  ];

  if (!isEmpty(category)) {
    breadcrumbItems = breadcrumbItems.concat(
      <Breadcrumb.Item key={`category_${category}`}>
        <Typography.Text>{category.name}</Typography.Text>
      </Breadcrumb.Item>,
    );
  }
  if (params?.search) {
    breadcrumbItems = breadcrumbItems.concat(
      <Breadcrumb.Item key={path}>
        <Typography.Text>{params?.search}</Typography.Text>
      </Breadcrumb.Item>,
    );
  }
  return (
    <Breadcrumb separator=">" className="breadcrumbs">
      {breadcrumbItems}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
