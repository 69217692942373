import React from 'react';
import Typography from 'antd/es/typography';
import Space from 'antd/es/space';
import { useSelector } from 'react-redux';
import Products from 'redux/products';
import Categories from 'redux/categories';

const SearchResults = ({ id }) => {
  const product = useSelector((state) => Products.selectors.selectById(state, id));
  const category = useSelector(Categories.selectors.categorySearch)(product?.category);

  return (
    <Space>
      <Typography.Text strong>{product?.title}</Typography.Text>
      <Typography.Text type="secondary">{product?.description}</Typography.Text>
      <Typography.Text type="secondary">{category?.name}</Typography.Text>
    </Space>
  );
};
export default SearchResults;
