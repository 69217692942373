import { useCallback } from 'react';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import List from 'antd/es/list';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Categories from 'redux/categories';
import chunk from 'lodash/chunk';
import './index.scss';
import { Space } from 'antd';

const MultiMenu = ({ categoryId, onCategoryPress }) => {
  const category = useSelector((state) => Categories.selectors.selectById(state, categoryId));
  const childrenCategories = category?.children;
  const dividedCategories = chunk(childrenCategories, 4);

  const onItemClick = useCallback(
    (id) => () => {
      if (onCategoryPress) onCategoryPress(id);
    },
    [onCategoryPress],
  );
  const onSubItemClick = useCallback(
    (id) => () => {
      if (onCategoryPress) onCategoryPress(id);
    },
    [onCategoryPress],
  );
  return (
    <Row gutter={30} className="categories-dropdown-menu">
      <Space align="start">
        {dividedCategories.map((categoryGroup) => (
          <Col span={8}>
            <List
              split={false}
              itemLayout="vertical"
              dataSource={categoryGroup}
              renderItem={(singleCategory) => {
                return (
                  <List.Item key={`category_${singleCategory.id}`}>
                    <Row key={singleCategory.id}>
                      <Button type="text" onClick={onItemClick(singleCategory.id)} key={singleCategory.id}>
                        <Typography.Text strong>{singleCategory.name}</Typography.Text>
                      </Button>
                    </Row>
                    {singleCategory.children?.map((subCategory) => (
                      <Row key={subCategory.id}>
                        <Button type="text" key={subCategory.id} onClick={onSubItemClick(subCategory.id)}>
                          <Typography.Text type="secondary">{subCategory.name}</Typography.Text>
                        </Button>
                      </Row>
                    ))}
                  </List.Item>
                );
              }}
            />
          </Col>
        ))}
      </Space>
    </Row>
  );
};
MultiMenu.PropType = {
  categoryId: PropTypes.number,
  onCategoryPress: PropTypes.func,
};
export default MultiMenu;
