import { Typography } from 'antd';
import PropTypes from 'prop-types';
import './index.scss';

const IconText = ({ textUnderlined, text, iconComponent, onIconClick }) => {
  return (
    <div className="icon-text-container" onClick={onIconClick}>
      {iconComponent}
      <Typography.Text className={textUnderlined ? 'text-underlined' : ''}>{text}</Typography.Text>
    </div>
  );
};

IconText.propTypes = {
  textUnderlined: PropTypes.bool,
  text: PropTypes.string,
  iconComponent: PropTypes.node,
  onIconClick: PropTypes.func,
};

export default IconText;
