import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import adapter from 'redux/brands/adapter';
import { cartAdapter } from './adapter';
import { addToCart, fetchCart, removeFromCart } from './thunks';
import { submitOrderAddress } from '../orders/thunks';

import values from 'lodash/values';
import set from 'lodash/set';

export const slice = createSlice({
  name: 'cart',
  initialState: cartAdapter.getInitialState({ errors: undefined, loading: false }),
  reducers: {
    resetCart(state, _) {
      cartAdapter.removeAll(state);
      state.order_id = undefined;
      state.shippingFees = undefined;
      state.totalCart = undefined;
      state.totalCheckout = undefined;
    },
    resetErrors(state, _) {
      state.errors = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeFromCart.fulfilled, (state, action) => {
        if (!action.payload.orderProduct) {
          adapter.removeAll(state);
          return;
        }
        const transformedOrders = values(action.payload.orderProduct).map((item) => set(item, 'id', item.product));
        adapter.setAll(state, transformedOrders);
        state.order_id = values(action.payload.cartOrder)[0].id;
      })
      .addCase(submitOrderAddress.fulfilled, (state, action) => {
        state.shippingFees = action.payload.address?.delivery_zone?.shipping_fees;
      })
      .addCase(fetchCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.errors = action.payload.data;
        state.loading = false;
      })
      .addMatcher(isAnyOf(fetchCart.fulfilled, addToCart.fulfilled), (state, action) => {
        if (!action.payload.cartOrder) return;
        const { orderProduct = [] } = action?.payload || {};
        const transformedOrders = values(orderProduct).map((item) => set(item, 'id', item.product));
        adapter.setAll(state, transformedOrders);
        state.order_id = values(action.payload.cartOrder)[0].id;
        state.totalCart = values(action.payload.cartOrder)[0].total_cart_price;
        state.totalCheckout = values(action.payload.cartOrder)[0].checkout_total_price;
        state.errors = undefined;
        state.loading = false;
      });
  },
});

const Cart = {
  slice,
};

export default Cart;
