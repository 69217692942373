import { useState } from 'react';
import { Button, Form, Input, Row, Col, Modal } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { editProfile } from 'redux/users/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import entries from 'lodash/entries';
import './index.scss';

const colSpacing = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xxl: 12,
};
const EditProfile = ({ history }) => {
  const { t } = useTranslation('screens');
  const currentUser = useSelector((store) => store.auth.currentUser);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const checkNumber = (_, value) => {
    if (!isNaN(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('signup.form.global_numbers_validation')));
  };

  const onSubmit = (user) => {
    setLoading(true);
    dispatch(editProfile({ user }))
      .then(unwrapResult)
      .then(() =>
        Modal.success({
          title: t('edit-profile.success.title'),
          content: t('edit-profile.success.content'),
          okText: t('edit-profile.success.ok'),
          onOk: () => {
            history.push('/profile/');
          },
        }),
      )
      .catch((errors) => {
        entries(errors)?.forEach(([name, errors]) => {
          form.setFields([
            {
              name,
              errors,
            },
          ]);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BorderedSection title={t('edit-profile.title')} description={t('edit-profile.subtitle')}>
      <Form form={form} initialValues={currentUser} onFinish={onSubmit} className="edit-profile-form">
        <Row gutter={50}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('signup.form.first_name_validation') }]}
              colon={false}
              name="first_name"
              label={t('signup.form.first_name')}
            >
              <Input placeholder={t('signup.form.first_name_placeholder')} />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('signup.form.last_name_validation') }]}
              colon={false}
              name="last_name"
              label={t('signup.form.last_name')}
            >
              <Input placeholder={t('signup.form.last_name_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={50}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('signup.form.email_validation') }]}
              colon={false}
              name="email"
              label={t('signup.form.email')}
            >
              <Input placeholder={t('signup.form.email_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="button-wrapper">
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                {t('edit-profile.edit')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BorderedSection>
  );
};
export default EditProfile;
