import { createSelector } from '@reduxjs/toolkit';
import values from 'lodash/values';
import memoize from 'lodash/memoize';
import take from 'lodash/take';
import adapter from './adapter';
import parseISO from 'date-fns/esm/parseISO';
import compareDesc from 'date-fns/esm/compareDesc';

export const selectWithPromotionProducts = createSelector(
  adapter.getSelectors((store) => store['products']).selectEntities,
  (items) =>
    memoize((filteredProducts, sortType) => {
      return filteredProducts;
    }),
);

const productsSortedBy = createSelector(adapter.getSelectors((store) => store['products']).selectEntities, (items) =>
  memoize((filteredProducts, sortType = 'featured') => {
    return filteredProducts;
  }),
);
const selectFavorites = createSelector(adapter.getSelectors((store) => store['products']).selectEntities, (items) =>
  values(items)
    .filter((item) => item.favorite)
    .map((item) => item.id),
);

export const selectProductsByCategory = createSelector(
  adapter.getSelectors((store) => store['products']).selectEntities,
  (items) =>
    memoize((category_id, count, current_product_id) => {
      const current_product = parseInt(current_product_id);
      let products = values(items).filter((item) => item.id !== current_product);
      return take(
        products.filter((product) => product.category === category_id).map((item) => item.id),
        count,
      );
    }),
);

export const selectLatestProducts = createSelector(
  adapter.getSelectors((store) => store['products']).selectEntities,
  (items) =>
    memoize((count) => {
      let products = values(items).sort((a, b) => compareDesc(parseISO(a.created_at), parseISO(b.created_at)));
      return take(
        products.map((item) => item.id),
        count,
      );
    }),
);

export const selectSingleProduct = createSelector(
  adapter.getSelectors((store) => store['products']).selectEntities,
  (items) =>
    memoize((id) => {
      const product = items[id];
      return product
        ? {
            ...product,
            images: product.images?.map((image) => ({
              original: image.versions.large,
              thumbnail: image.versions.thumbnail,
            })),
          }
        : {};
    }),
);

const selectors = {
  productsSortedBy,
  selectFavorites,
  selectWithPromotionProducts,
  selectSingleProduct,
  ...adapter.getSelectors((state) => state['products']),
};

export default selectors;
