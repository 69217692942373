import { useEffect, useState, useCallback } from 'react';
import { Form } from 'antd';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Modal from 'antd/es/modal';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'use-timer';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import useMountedState from 'react-use/lib/useMountedState';
import { userConfirmation, userForgotPassword, userResendOTP } from 'redux/auth/thunks';
import BorderedSection from 'components/organsims/BorderedSection';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12 },
    xxl: { span: 12 },
  },
};

const OTP = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('screens');
  const { state: { phone_number, previous: previousRoute } = {} } = history.location;
  if (!phone_number || !previousRoute) history.replace('/404');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [lockResend, setLockResend] = useState(false);

  const { time, start, reset, advanceTime } = useTimer({
    initialTime: 120,
    endTime: 0,
    timerType: 'DECREMENTAL',
  });

  const isMounted = useMountedState();

  const onResendNotConfirmed = useCallback(
    () =>
      dispatch(userResendOTP({ phone_number }))
        .then(unwrapResult)
        .then(() => {
          reset();
          start();
        })
        .catch((error) => {
          Modal.error({
            title: t('otp.error'),
            content: (
              <>
                {error?.data?.error?.map((errorItem, index) => (
                  <Typography.Text key={index}>{errorItem}</Typography.Text>
                ))}
              </>
            ),
          });
        })
        .finally(() => setLockResend(false)),
    [dispatch, phone_number, reset, start, t],
  );

  const onResendConfirmed = useCallback(
    () =>
      dispatch(userForgotPassword({ phone_number }))
        .then(unwrapResult)
        .then(() => {
          reset();
          start();
        })
        .catch((error) => {
          if (error?.status === 404) {
            form.setFields([
              {
                name: 'phone_number',
                errors: [t('forgot_password.feedback.errors.404')],
              },
            ]);
          } else {
            Modal.error({
              title: t('forgot_password.feedback.errors.error'),
              content: (
                <>
                  {error?.data?.error?.map((error) => (
                    <Typography.Text key={error}>{error}</Typography.Text>
                  ))}
                </>
              ),
            });
          }
        })
        .finally(() => setLockResend(false)),
    [dispatch, form, phone_number, reset, start, t],
  );

  const onResendOTP = useCallback(() => {
    if (time === 0) {
      setLockResend(true);
      switch (previousRoute) {
        case 'forget_password':
          onResendConfirmed();
          break;
        default:
          onResendNotConfirmed();
      }
    } else {
      Modal.warning({ content: t('otp.timer_error', { time }) });
    }
  }, [previousRoute, onResendConfirmed, onResendNotConfirmed, t, time]);

  const onConfirmUser = (data) =>
    dispatch(userConfirmation(data))
      .then(unwrapResult)
      .then(() => {
        Modal.success({
          title: t('otp.feedback.success.title'),
          content: t('otp.feedback.success.content'),
          okText: t('otp.feedback.success.ok'),
          onOk: () => history.push('/auth/signin'),
        });
      })
      .catch((error) => {
        if (error?.status === 404)
          form.setFields([
            {
              name: 'sms_confirmation_otp',
              errors: [t('otp.feedback.errors.404')],
            },
          ]);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });

  const onFinish = (data) => {
    setLoading(true);
    switch (previousRoute) {
      case 'forget_password':
        history.push(`/auth/reset_password/${data.sms_confirmation_otp}`);
        break;
      default:
        onConfirmUser(data);
    }
  };

  useEffect(() => {
    if (previousRoute === 'signin') advanceTime(120);
    else start();
  }, [start, advanceTime, previousRoute]);

  return (
    <BorderedSection title={t('otp.title')}>
      <Form form={form} name="otp" onFinish={onFinish} layout="vertical" {...formItemLayout}>
        <Form.Item
          name="sms_confirmation_otp"
          label={t('otp.form.otp_code')}
          rules={[
            {
              required: true,
              message: t('otp.form.otp_code_validation'),
            },
          ]}
        >
          <Input placeholder={t('otp.form.otp_code_placeholder')} />
        </Form.Item>
        <Space>
          <Button type="text" disabled={time !== 0 || lockResend} onClick={onResendOTP}>
            <Typography.Text type="secondary">{t('otp.form.send_again')}</Typography.Text>
          </Button>
          <Space>
            <ClockCircleOutlined />
            <Typography.Text>{time}</Typography.Text>
          </Space>
        </Space>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('otp.form.verify')}
          </Button>
        </Form.Item>
      </Form>
    </BorderedSection>
  );
};

export default OTP;
