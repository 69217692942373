import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Row, Form, Input, Button, Typography, Space, Modal } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMountedState } from 'react-use';
import { userSignin } from 'redux/auth/thunks';

const SignIn = ({ history }) => {
  const form = Form.useForm();
  const { t } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(userSignin(values))
      .then(unwrapResult)
      .catch((errors) => {
        if (errors?.need_confirmation)
          Modal.error({
            title: screenTranslation('signin.errors.title'),
            okText: screenTranslation('signin.errors.otp'),
            content: (
              <>
                {errors?.errors?.map((error) => (
                  <Typography.Text>{error}</Typography.Text>
                ))}
              </>
            ),
            onOk: () => {
              history.push('/auth/otp_confirmation', {
                previous: 'signin',
                phone_number: values.phone_number,
              });
            },
          });
        else
          Modal.error({
            title: screenTranslation('signin.errors.title'),
            okText: screenTranslation('signin.errors.ok'),
            content: (
              <>
                {errors?.errors?.map((error) => (
                  <Typography.Text>{error}</Typography.Text>
                ))}
              </>
            ),
          });
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  const onForgotPassword = () => {
    history.push('/auth/forgot_password');
  };

  return (
    <Row gutter={[20, 20]}>
      <Col xs={24} sm={24} md={12} lg={12} xxl={12} span={12}>
        <BorderedSection title={t('signin')}>
          <Form {...form} onFinish={onSubmit}>
            <Form.Item
              rules={[{ required: true, message: screenTranslation('signin.form.phone_number_validation') }]}
              colon={false}
              name="phone_number"
              label={screenTranslation('signin.form.phone_number')}
            >
              <Input placeholder={screenTranslation('signin.form.phone_number_placeholder')} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: screenTranslation('signin.form.password_validation') }]}
              colon={false}
              name="password"
              label={screenTranslation('signin.form.password')}
            >
              <Input.Password placeholder={screenTranslation('signin.form.password_placeholder')} />
            </Form.Item>

            <Row justify="space-between" align="center">
              <Form.Item>
                <Button loading={loading} htmlType="submit" type="primary">
                  {t('signin')}
                </Button>
              </Form.Item>
              <Button onClick={onForgotPassword} type="text">
                {t('forgot_password')}
              </Button>
            </Row>
          </Form>
        </BorderedSection>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xxl={12} span={12}>
        <BorderedSection title={t('signup')}>
          <Space size="large" direction="vertical">
            <Typography.Text>{screenTranslation('signin.signup_prompt')}</Typography.Text>
            <Link to="/auth/signup">
              <Button type="primary">{t('signup')}</Button>
            </Link>
          </Space>
        </BorderedSection>
      </Col>
    </Row>
  );
};

export default SignIn;
