import { List, Space, Typography } from 'antd';
import ProductPreview from 'components/molecules/ProductPreview';
import BorderedSection from 'components/organsims/BorderedSection';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Orders from 'redux/orders';
import localizeNumber from 'utils/localizeNumber';
import { humanize } from 'utils/stringsHelper';
import './index.scss';

const renderItem = (item) => (
  <List.Item>
    <ProductPreview productOrder={item} />
  </List.Item>
);

const OrderItem = ({ orderId }) => {
  const { t } = useTranslation('screens');
  const order = useSelector((state) => Orders.selectors.selectById(state, orderId));

  const products = useSelector(Orders.selectors.selectOrderProducts)(orderId);

  const { id, checkout_total_price, checkout_shipping_fees, payment_method, status } = order;

  return (
    <BorderedSection
      title={t('profile.orders.order_title', {
        order_number: localizeNumber(id),
        order_type: humanize(payment_method),
      })}
    >
      <Space direction="vertical" className="orders-list-wrapper" fullWidth>
        <Typography.Text strong>{t('profile.orders.status', { status: humanize(status) })}</Typography.Text>
        <Typography.Text strong>
          {t('profile.orders.shipping', { shipping: localizeNumber(checkout_shipping_fees) })}
        </Typography.Text>
        <Typography.Text strong>
          {t('profile.orders.total', { price: localizeNumber(checkout_total_price) })}
        </Typography.Text>
        <List dataSource={products} renderItem={renderItem} split={false} />
      </Space>
    </BorderedSection>
  );
};

export default OrderItem;
