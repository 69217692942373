import { useCallback } from 'react';
import Typography from 'antd/es/typography';
import Button from 'antd/es/button';
import Menu from 'antd/es/menu';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import Categories from 'redux/categories';
import Brands from 'redux/brands';
import MultiMenu from 'components/molecules/DropDown/components/index.multi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { Space } from 'antd';
import List from 'antd/es/list';
import queryString from 'query-string';
import Promotions from 'redux/promotions';
import './index.scss';

const CategoriesHeader = ({ onCategoryPress }) => {
  const mainCategories = useSelector(Categories.selectors.mainCategories);
  const singleCategory = useSelector(Categories.selectors.byId);
  const brands = useSelector(Brands.selectors.selectAll);
  const promotions = useSelector(Promotions.selectors.selectAll);
  const { t, i18n } = useTranslation(['header', 'molecules', 'footer']);

  const onTitleClick = useCallback(
    (id) => () => {
      if (onCategoryPress) onCategoryPress(id);
    },
    [onCategoryPress],
  );

  const renderPromotions = () => {
    if (promotions.length < 3)
      return promotions?.map((promotion) => (
        <Link to={{ pathname: `/promotions/${promotion.slug}`, state: { promotionId: promotion.id } }}>
          <Button type="text">{promotion.name}</Button>
        </Link>
      ));

    return (
      <Menu.SubMenu
        key={`promotions`}
        title={
          <Button type="text">
            <Typography.Text>{t('molecules:header:promotions')}</Typography.Text>
          </Button>
        }
      >
        {promotions.map((promotion) => (
          <Row key={promotion.id}>
            <Link to={`/promotions/${promotion.slug}`}>
              <Button type="text">
                <Typography.Text strong>{promotion.name}</Typography.Text>
              </Button>
            </Link>
          </Row>
        ))}
      </Menu.SubMenu>
    );
  };

  return (
    <div className="categories-header">
      <Menu mode="horizontal">
        {mainCategories.map((categoryId) => {
          const category = singleCategory(categoryId);
          return (
            <Menu.SubMenu
              key={`category_${categoryId}`}
              title={
                <Button onClick={onTitleClick(categoryId)} type="text">
                  <Typography.Text>{category?.name}</Typography.Text>
                </Button>
              }
            >
              <MultiMenu categoryId={categoryId} onCategoryPress={onCategoryPress} />
            </Menu.SubMenu>
          );
        })}
        <Link to="/special-offers">
          <Button type="text">{t('footer:special')}</Button>
        </Link>
        {renderPromotions()}
        <Menu.SubMenu
          key={`brands`}
          title={
            <Button type="text">
              <Typography.Text>{t('molecules:header:brands')}</Typography.Text>
            </Button>
          }
        >
          <Row gutter={30} className="brands-dropdown-menu">
            <Space align="start">
              {brands.map((brand) => (
                <Col span={8}>
                  <List
                    split={false}
                    itemLayout="vertical"
                    dataSource={[brand]}
                    renderItem={(brand) => {
                      return (
                        <List.Item key={`brand_${brand.id}`}>
                          <Row key={brand.id}>
                            <Link to={`/products?${queryString.stringify({ brand_id: brand.id })}`}>
                              <Button type="text">
                                <Typography.Text strong>
                                  {i18n.language === 'ar' && brand.name_ar ? brand.name_ar : brand.name_en}
                                </Typography.Text>
                              </Button>
                            </Link>
                          </Row>
                        </List.Item>
                      );
                    }}
                  />
                </Col>
              ))}
            </Space>
          </Row>
        </Menu.SubMenu>
        <Link to="/about-us">
          <Button type="text">{t('molecules:header:about_us')}</Button>
        </Link>
      </Menu>
    </div>
  );
};

CategoriesHeader.propTypes = {
  onCategoryPress: PropTypes.func,
};

export default CategoriesHeader;
