import SingleProduct from 'components/molecules/SingleProduct';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import selectors, { selectProductsByCategory } from 'redux/products/selectors';
import isEmpty from 'lodash/isEmpty';
import { fetchProducts } from 'redux/products/thunks';
import BorderedSection from 'components/organsims/BorderedSection';
import ProductSlider from 'components/organsims/ProductSlider';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { authSlice } from 'redux/auth';
import './index.scss';

const Product = () => {
  const { t } = useTranslation('screens');
  const dispatch = useDispatch();
  const { productId } = useParams();
  const product = useSelector((store) => selectors.selectById(store, productId));
  const similarItems = useSelector(selectProductsByCategory)(product?.category, 10, productId);
  useEffect(() => {
    if (isEmpty(product)) return;
    dispatch(authSlice.actions.updateRecentlyViewed(product.id));
    dispatch(fetchProducts({ params: { category_id: product.category } }));
  }, [product, dispatch]);

  return (
    <Space className="product-screen" size="large" direction="vertical">
      <Helmet>
        <title>{product?.title}</title>
        <meta name="title" content={product?.title} />
        <meta name="description" content={product?.description} />
        <meta name="image" content={product?.images?.[0]?.versions?.thumbnail} />
        <meta name="url" content={`https://abdulaziz-stores.com/products/${product?.id}`} />

        <meta property="og:title" content={product?.title} />
        <meta property="og:description" content={product?.description} />
        <meta property="og:image" content={product?.images?.[0]?.versions?.thumbnail} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="200" />
        <meta property="og:url" content={`https://abdulaziz-stores.com/products/${product?.id}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <SingleProduct productId={productId} />
      {!isEmpty(similarItems) ? (
        <BorderedSection title={t('product.related_products.title')}>
          <ProductSlider products={similarItems} />
        </BorderedSection>
      ) : (
        <></>
      )}
    </Space>
  );
};

export default Product;
