import { Steps, Row, Col, Modal, Typography } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { useTranslation } from 'react-i18next';
import ChooseAddress from './components/ChooseAddress';
import OrderSummary from 'components/organsims/OrderSummary';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentMethod from './components/PaymentMethod';
import { submitOrderAddress } from 'redux/orders/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchCart } from 'redux/cart/thunks';
import { cartErrors, cartLoading, itemsPrice } from 'redux/cart/selectors';
import NationalId from './components/nationalId';
import Cart from 'redux/cart';
import { useHistory } from 'react-router-dom';

const Checkout = () => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const order_id = useSelector((state) => state.cart.order_id);
  const totalSum = useSelector(itemsPrice);
  const loading = useSelector(cartLoading);
  const errors = useSelector(cartErrors);
  const [opend, setOpend] = useState(false);

  useEffect(() => {
    if (!loading && errors && errors.sap_sync) {
      setOpend(true);
      Modal.error({
        content: (
          <>
            {Object.keys(errors).map((errorKey) => (
              <Typography.Text>{errors[errorKey]}</Typography.Text>
            ))}
          </>
        ),
        okText: t('checkout.continue'),
        onOk: () => {
          setOpend(false);
          history.push('/');
        },
      });
    }
  }, [errors]);

  const [checkoutStep, setCheckoutStep] = useState(0);
  const dispatch = useDispatch();

  const onStepChange = (step) => {
    if (step < checkoutStep) setCheckoutStep(step);
  };

  const onPickAddress = useCallback(
    (address_id) => {
      dispatch(submitOrderAddress({ address_id, order_id })).then(() =>
        dispatch(fetchCart())
          .then(unwrapResult)
          .then(() => setCheckoutStep(1))
          .catch(() => {
            if (errors?.sap_sync) Modal.error({ title: t('signin.errors.title'), okText: t('signin.errors.title') });
          }),
      );
    },
    [dispatch, setCheckoutStep, order_id, t],
  );

  const onSubmitNationalId = () => {
    setCheckoutStep(2);
  };

  const onReload = () => {
    setCheckoutStep(0);
  };

  const nationalIdRequired = () => totalSum >= 150_000;

  return (
    <Row className="cart" gutter={[20, 20]}>
      <Col xs={24} sm={24} md={24} lg={17} xl={17} xxl={20}>
        <BorderedSection title={t('checkout.title')}>
          <>
            <Steps progressDot current={checkoutStep} onChange={onStepChange}>
              <Steps.Step title={t('checkout.steps.choose_address')} />
              {nationalIdRequired() && <Steps.Step title={t('checkout.steps.add_national_id')} />}
              <Steps.Step title={t('checkout.steps.payment_method')} />
            </Steps>
            {checkoutStep === 0 ? (
              <ChooseAddress onPickAddress={onPickAddress} />
            ) : checkoutStep === 1 && nationalIdRequired() ? (
              <NationalId onSubmitNationalId={onSubmitNationalId} />
            ) : (
              <PaymentMethod onReload={onReload} />
            )}
          </>
        </BorderedSection>
      </Col>
      <Col className="cart-summary" xs={24} sm={24} md={24} lg={7} xl={7} xxl={4}>
        {!loading && <OrderSummary />}
      </Col>
    </Row>
  );
};
export default Checkout;
