import React from 'react';
import PropTypes from 'prop-types';
import CardLabels from 'components/atoms/CardLabels';
import Price from 'components/atoms/Price';
import { Card, Typography, Divider, Button } from 'antd';
import { HeartFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as HoverImage } from 'assets/icons/actions.svg';
import localizeNumber from 'utils/localizeNumber';
import Products from 'redux/products';
import brands from 'redux/brands';
import './index.scss';

const ProductCard = ({ productId, removeFromFavorites }) => {
  const { t, i18n } = useTranslation('product-card');
  const product = useSelector((state) => Products.selectors.selectById(state, productId));
  const { title, title_ar, images, brand: brand_id } = product || {};
  const brand = useSelector((state) => brands.selectors.selectById(state, brand_id));

  return (
    <Link to={`/products/${productId}`} className="product-card-wrapper">
      <Card
        cover={
          <React.Fragment>
            <div className="card-cover">
              <img alt={title} src={images?.[0]?.versions?.medium} />
              {product?.featured && <CardLabels labels={[t('featured')]} />}
            </div>
            <HoverImage />
          </React.Fragment>
        }
        actions={
          product?.favorite && removeFromFavorites
            ? [
                <Button type="primary" icon={<HeartFilled />} onClick={() => removeFromFavorites(product?.id)}>
                  {t('favorite-remove')}
                </Button>,
              ]
            : null
        }
        hoverable
      >
        <Divider />
        <Typography.Title level={2} strong>
          {i18n.language === 'ar' ? title_ar : title}
        </Typography.Title>
        <Typography.Text className="subtitle">{i18n.language === 'ar' ? brand?.name_ar : brand?.name}</Typography.Text>
        {product?.promotion && product?.price !== product?.price_after_promotion ? (
          <React.Fragment>
            <Price
              discounted
              text={t('price', {
                price: localizeNumber(Number.parseFloat(product?.price_after_promotion), true),
              })}
            />
            <Price
              text={t('price', {
                price: localizeNumber(Number.parseFloat(product?.price), true),
              })}
              crossed
            />
            <Price
              discounted
              text={t('discount', {
                percentage: localizeNumber(
                  Number.parseFloat(100 - (product?.price_after_promotion * 100) / product?.price),
                  true,
                ),
              })}
            />
          </React.Fragment>
        ) : (
          <Price
            text={t('price', {
              price: localizeNumber(Number.parseFloat(product?.price), true),
            })}
          />
        )}
        {product?.stock === 0 && <Typography.Text type="danger">{t('out_stock')}</Typography.Text>}
      </Card>
    </Link>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  removeFromFavorites: PropTypes.func,
};

export default ProductCard;
