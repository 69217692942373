import { useCallback, useMemo } from 'react';
import { Dropdown, Button, Menu, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.scss';

const ProductSort = ({ onSortBy }) => {
  const { t } = useTranslation('products-sort');
  const handleMenuClick = useCallback(
    ({ key }) => {
      if (onSortBy) onSortBy(key);
    },
    [onSortBy],
  );

  const renderSortingMenu = useMemo(
    () => (
      <Menu onClick={handleMenuClick} className="sort-by-wrapper">
        <Menu.Item key="lowest-price">
          <Typography.Text>{t('lowest')}</Typography.Text>
        </Menu.Item>
        <Menu.Item key="highest-price">
          <Typography.Text>{t('highest')}</Typography.Text>
        </Menu.Item>
        <Menu.Item key="latest">
          <Typography.Text>{t('latest')}</Typography.Text>
        </Menu.Item>
        <Menu.Item key="featured">
          <Typography.Text>{t('featured')}</Typography.Text>
        </Menu.Item>
      </Menu>
    ),
    [handleMenuClick, t],
  );

  return (
    <Dropdown overlay={renderSortingMenu}>
      <Button>
        <DownOutlined />
        <Typography.Text>{t('sort-by')}</Typography.Text>
      </Button>
    </Dropdown>
  );
};

ProductSort.propTypes = {
  onSortBy: PropTypes.func,
};
export default ProductSort;
