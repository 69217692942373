import { unwrapResult } from '@reduxjs/toolkit';
import { Row, Col, Form, Input, Button, Modal, Typography } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { userForgotPassword } from 'redux/auth/thunks';

const ForgotPassword = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isMounted = useMountedState();

  const { t: authenticationTranslation } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(userForgotPassword(values))
      .then(unwrapResult)
      .then(() => {
        Modal.success({
          title: screenTranslation('forgot_password.feedback.success.title'),
          content: screenTranslation('forgot_password.feedback.success.content'),
          okText: screenTranslation('forgot_password.feedback.success.ok'),
          onOk: () => {
            history.push('/auth/otp_confirmation', {
              previous: 'forget_password',
              phone_number: values.phone_number,
            });
          },
        });
      })
      .catch((error) => {
        if (error?.status === 404) {
          form.setFields([
            {
              name: 'phone_number',
              errors: [screenTranslation('forgot_password.feedback.errors.404')],
            },
          ]);
        } else {
          Modal.error({
            title: screenTranslation('forgot_password.feedback.errors.error'),
            content: (
              <>
                {error?.data?.error?.map((error) => (
                  <Typography.Text key={error}>{error}</Typography.Text>
                ))}
              </>
            ),
          });
        }
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  return (
    <BorderedSection
      title={authenticationTranslation('forgot_password')}
      description={screenTranslation('forgot_password.sub_title')}
    >
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xxl={12}>
          <Form form={form} onFinish={onSubmit}>
            <Form.Item
              rules={[{ required: true, message: screenTranslation('forgot_password.form.phone_number_validation') }]}
              colon={false}
              name="phone_number"
              label={screenTranslation('forgot_password.form.phone_number')}
            >
              <Input placeholder={screenTranslation('forgot_password.form.phone_number_placeholder')} />
            </Form.Item>

            <Form.Item>
              <Button loading={loading} htmlType="submit" type="primary">
                {authenticationTranslation('forgot_password')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </BorderedSection>
  );
};

export default ForgotPassword;
