import { useState, useCallback, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { Button, Divider, List, Typography, Slider, Checkbox, Select, Space, InputNumber, Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
// import localizeNumber from 'utils/localizeNumber';
import Brands from 'redux/brands';
import Categories from 'redux/categories';

import { useLoadingSelector } from 'redux/selectors';
import { fetchBrands } from 'redux/brands/thunks';
import { fetchCategories } from 'redux/categories/thunks';

import { unwrapResult } from '@reduxjs/toolkit';
import InfiniteScroll from 'react-infinite-scroller';
import store from 'redux/store';
import { fetchProducts } from 'redux/products/thunks';
import './index.scss';

const SideFilter = ({ onFilterSubmit, showCategory, hideBrand }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { promotionId } = useParams();
  const { t, i18n } = useTranslation('molecules');

  const [selectedBrands, setSelectedBrands] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();
  const [priceRange, setPriceRange] = useState();
  // const [sliderRange, setSliderRange] = useState();
  const [priceFilter, setPriceFilter] = useState([]);
  const [brandsList, setBrandsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const brandsSelector = useSelector(Brands.selectors.byId);
  const childrenCategories = useSelector(Categories.selectors.childrenCategories);
  const isBrandsLoading = useLoadingSelector(fetchBrands);
  let searchParams = queryString.parse(search);
  const brandsMeta = store.getState()._pagination[
    `brands/fetchAll${searchParams ? `?category_id=${searchParams?.category_id}` : ''}`
  ];

  const onCategorySelect = useCallback(
    (item) => {
      setSelectedCategory(item);
    },
    [setSelectedCategory],
  );

  const onBrandsFetch = useCallback(() => {
    dispatch(fetchBrands({ params: { category_id: searchParams?.category_id } }))
      .then(unwrapResult)
      .then((response) => {
        setBrandsList([...brandsList, ...response.result]);
      });
  }, [brandsList, dispatch, searchParams?.category_id]);

  const onFilterPress = useCallback(
    () =>
      onFilterSubmit({
        priceRange: priceFilter,
        brands: Object.keys(selectedBrands),
        category_id: selectedCategory,
      }),
    [onFilterSubmit, priceFilter, selectedBrands, selectedCategory],
  );

  const onPressItem = useCallback(
    (item) => () => {
      if (!selectedBrands[item]) {
        setSelectedBrands({ ...selectedBrands, [item]: true });
      } else {
        setSelectedBrands(
          pickBy(selectedBrands, (_, key) => {
            return key !== item.toString();
          }),
        );
      }
    },
    [selectedBrands],
  );

  const onLowPriceChange = (value) => {
    setPriceFilter([value, priceFilter[1]]);
  };

  const onHighPriceChange = (value) => {
    setPriceFilter([priceFilter[0], value]);
  };

  const renderBrandsItem = useCallback(
    (item) => {
      const brand = brandsSelector(item)[0];
      return (
        <div>
          <Checkbox checked={selectedBrands[item]} onChange={onPressItem(item)}>
            <Typography.Text type={selectedBrands[item] ? 'primary' : 'secondary'}>
              {i18n.language === 'ar' && brand.name_ar ? brand.name_ar : brand.name}
            </Typography.Text>
          </Checkbox>
        </div>
      );
    },
    [brandsSelector, i18n.language, onPressItem, selectedBrands],
  );
  /**Fetch brands and products(to update price) when category changes */
  useEffect(() => {
    dispatch(
      fetchBrands({ params: { category_id: searchParams?.category_id, promotion_id: promotionId }, refresh: true }),
    )
      .then(unwrapResult)
      .then((response) => {
        setSelectedBrands({});
        setBrandsList(response.result);
      });
  }, [dispatch, promotionId, searchParams?.category_id]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchCategories({ params: { category_id: searchParams?.category_id }, refresh: true }))
      .then(unwrapResult)
      .then((response) => {
        setSelectedBrands({});
      });
    dispatch(fetchProducts({ params: { category_id: searchParams?.category_id }, refresh: true }))
      .then(unwrapResult)
      .then((response) => {
        setPriceRange([response.meta.min_price, response.meta.max_price]);
        // setSliderRange([response.meta.min_price, response.meta.max_price]);
        setIsLoading(false);
      });
  }, [dispatch, searchParams?.category_id]);
  // searchParams?.category_id

  return (
    <div className="filter-container">
      <div className="filter-info-wrapper">
        <Typography.Title level={2}>{t('side-filter.price')}</Typography.Title>
        <Divider />
        {/* {!isLoading && sliderRange && priceRange && (
          <div className="slider-container">
            <Slider
              range={{ draggableTrack: true }}
              defaultValue={sliderRange}
              min={priceRange[0]}
              max={priceRange[1]}
              onAfterChange={setSliderRange}
            />
            <Typography.Text type="secondary">
              {t('side-filter.price-range', {
                minPrice: localizeNumber(sliderRange[0]),
                maxPrice: localizeNumber(sliderRange[1]),
              })}
            </Typography.Text>
          </div>
        )} */}
        <Row gutter={16} className="price-range-wrapper" justify="center">
          {isLoading ? (
            <Spin />
          ) : (
            <Fragment>
              <Col md={24} xl={12}>
                <Space size="small">
                  <Typography.Text>{t('side-filter.low-price')}</Typography.Text>
                  <InputNumber size="small" onChange={onLowPriceChange} min={0} placeholder={priceRange?.[0]} />
                </Space>
              </Col>
              <Col md={24} xl={12}>
                <Space size="small">
                  <Typography.Text>{t('side-filter.high-price')}</Typography.Text>
                  <InputNumber size="small" onChange={onHighPriceChange} min={0} placeholder={priceRange?.[1]} />
                </Space>
              </Col>
            </Fragment>
          )}
        </Row>
        <Divider />
        {!hideBrand && (
          <Fragment>
            <Typography.Title level={2}>{t('side-filter.brand')}</Typography.Title>
            <div className="list-infinite-container">
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={onBrandsFetch}
                hasMore={!isBrandsLoading && brandsMeta?.next_page}
                useWindow={false}
              >
                <List dataSource={brandsList} renderItem={renderBrandsItem} loading={isBrandsLoading} />
              </InfiniteScroll>
            </div>
            <Divider />
          </Fragment>
        )}

        {showCategory ? (
          <div>
            <Typography.Title level={2}>{t('side-filter.category')}</Typography.Title>

            <Select placeholder={t('side-filter.category-Select')} onChange={onCategorySelect} allowClear>
              {childrenCategories.map((category) => (
                <Select.Option value={category.id}>{category.name}</Select.Option>
              ))}
            </Select>
          </div>
        ) : (
          ''
        )}
      </div>
      <Button type="primary" onClick={onFilterPress} block className="custom-button">
        {t('side-filter.filter-button')}
      </Button>
    </div>
  );
};

SideFilter.PropType = {
  initialPriceRange: PropTypes.array,
  priceBoundaries: PropTypes.array,
  onFilterSubmit: PropTypes.func,
  showCategory: PropTypes.bool,
};
export default SideFilter;
