import { unwrapResult } from '@reduxjs/toolkit';
import { Col, Row, Form, Input, Button, Modal } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import entries from 'lodash/entries';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { userSignup } from 'redux/auth/thunks';

const colSpacing = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xxl: 12,
};

const Signup = ({ history }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('authentication');
  const { t: screenTranslation } = useTranslation('screens');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const checkNumber = (_, value) => {
    if (!isNaN(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('global_numbers_validation')));
  };

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(userSignup({ user: values }))
      .then(unwrapResult)
      .then(() => {
        Modal.success({
          title: screenTranslation('signup.success.title'),
          content: screenTranslation('signup.success.content'),
          okText: screenTranslation('signup.success.ok'),
          onOk: () => {
            history.push('/auth/otp_confirmation', { previous: 'signup', phone_number: values.phone_number });
          },
        });
      })
      .catch((_errors) => {
        entries(_errors)?.forEach(([name, errors]) => {
          form.setFields([
            {
              name,
              errors,
            },
          ]);
        });
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  return (
    <BorderedSection title={t('signup')}>
      <Form name="user" form={form} onFinish={onSubmit}>
        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: screenTranslation('signup.form.first_name_validation') }]}
              colon={false}
              name="first_name"
              label={screenTranslation('signup.form.first_name')}
            >
              <Input placeholder={screenTranslation('signup.form.first_name_placeholder')} />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: screenTranslation('signup.form.last_name_validation') }]}
              colon={false}
              name="last_name"
              label={screenTranslation('signup.form.last_name')}
            >
              <Input placeholder={screenTranslation('signup.form.last_name_placeholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[
                { required: true, message: screenTranslation('signup.form.email_validation') },
                { type: 'email', message: screenTranslation('signup.form.email_wrong_format') },
              ]}
              colon={false}
              name="email"
              label={screenTranslation('signup.form.email')}
            >
              <Input placeholder={screenTranslation('signup.form.email_placeholder')} />
            </Form.Item>
          </Col>

          <Col {...colSpacing}>
            <Form.Item
              rules={[
                { required: true, message: screenTranslation('signup.form.phone_number_validation') },
                { validator: checkNumber },
              ]}
              colon={false}
              name="phone_number"
              label={screenTranslation('signup.form.phone_number')}
            >
              <Input placeholder={screenTranslation('signup.form.phone_number_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: screenTranslation('signup.form.password_validation') }]}
              colon={false}
              name="password"
              label={screenTranslation('signup.form.password')}
            >
              <Input.Password placeholder={screenTranslation('signup.form.password_placeholder')} />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[
                { required: true, message: screenTranslation('signup.form.password_confirmation_validation') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(screenTranslation('signup.form.password_confirmation_matching'));
                  },
                }),
              ]}
              colon={false}
              name="password_confirmation"
              label={screenTranslation('signup.form.password_confirmation')}
            >
              <Input.Password placeholder={screenTranslation('signup.form.password_confirmation_placeholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button loading={loading} htmlType="submit" type="primary">
            {t('signup')}
          </Button>
        </Form.Item>
      </Form>
    </BorderedSection>
  );
};

export default Signup;
