import { unwrapResult } from '@reduxjs/toolkit';
import { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cartCounter } from 'redux/cart/selectors';
import { rateOrders } from 'redux/orders/thunks';

import { Button, Modal, Space, Typography } from 'antd';

import { Rating } from 'react-simple-star-rating';

const fillColorArray = [
  '#f17a45',
  '#f17a45',
  '#f19745',
  '#f19745',
  '#f1a545',
  '#f1a545',
  '#f1b345',
  '#f1b345',
  '#f1d045',
  '#f1d045',
];

const RatingModal = (_, ref) => {
  const [prevOrderRatings, setPrevOrderRatings] = useState({});
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isRatingLoading, setIsRatingLoading] = useState(false);

  const count = useSelector(cartCounter);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('screens');

  const isRatingValid = () => Object.keys(prevOrderRatings).length === deliveredOrders.length;

  const onSubmitRating = () => {
    if (isRatingValid) {
      /**Submit rating here through api */
      setIsRatingLoading(true);
      const ratingsArray = Object.keys(prevOrderRatings).reduce(
        (acc, id) => [...acc, { id, rating: prevOrderRatings[id] }],
        [],
      );
      dispatch(rateOrders({ ratings: ratingsArray }))
        .then(unwrapResult)
        .then(() => {
          setModalVisible(false);
          if (count !== 0)
            setTimeout(() => {
              history.push('/checkout');
            }, 500);
        })
        .catch(() => {
          Modal.error({
            content: <Typography.Text>{t('cart.rating_modal.error')}</Typography.Text>,
          });
        })
        .finally(() => {
          setIsRatingLoading(false);
        });
    }
  };

  useImperativeHandle(ref, () => ({
    openModal: (orders) => {
      setModalVisible(true);
      setDeliveredOrders(orders);
    },
  }));

  return (
    <Modal
      title={t('cart.rating_modal.title')}
      centered
      visible={modalVisible}
      closable={false}
      footer={
        <Button onClick={onSubmitRating} type="primary" disabled={!isRatingValid()} loading={isRatingLoading}>
          {t('cart.rating_modal.ok_button')}
        </Button>
      }
    >
      {deliveredOrders.map((order) => {
        const onRatingChange = (value) => {
          setPrevOrderRatings((rating) => ({ ...rating, [order]: value / 20 }));
        };
        return (
          <Space key={order}>
            <Typography.Text>{t('cart.rating_modal.order_number', { order })}</Typography.Text>
            <Rating
              onClick={onRatingChange}
              ratingValue={prevOrderRatings[order] * 20 || 0}
              size={50}
              transition
              fillColorArray={fillColorArray}
            />
          </Space>
        );
      })}
    </Modal>
  );
};

export default forwardRef(RatingModal);
