import { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DefaultLayout from 'layouts/DefaultLayout';

import Guest from 'router/Guest';

export const history = createBrowserHistory();

const Router = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <DefaultLayout>
      <Route
        render={() => (
          <Switch>
            {/* <Route component={Dashboard} /> */}
            <Route component={Guest} />
          </Switch>
        )}
      />
    </DefaultLayout>
  );
};

export default Router;
