import { Pagination } from 'antd';
import PropTypes from 'prop-types';
import localizeNumber from 'utils/localizeNumber';
import './index.scss';

const EntityPagination = ({ onChange, defaultPageSize, total }) => {
  const translateNumber = (current, type, originalElement) => {
    if (type === 'page') {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <a>{localizeNumber(current, true)}</a>;
    }
    return originalElement;
  };

  return (
    <div className="pagination">
      <Pagination
        onChange={onChange}
        defaultCurrent={1}
        defaultPageSize={defaultPageSize}
        total={total}
        itemRender={(current, type, originalElement) => translateNumber(current, type, originalElement)}
        hideOnSinglePage
        responsive
      />
    </div>
  );
};

EntityPagination.propTypes = {
  onChange: PropTypes.func,
  defaultPageSize: PropTypes.number,
  total: PropTypes.number,
};

export default EntityPagination;
