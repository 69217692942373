import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import adapter from './adapter';
import * as thunks from './thunks';
import selectors from './selectors';
import Orders from 'redux/orders';
import { addToCart, fetchCart } from 'redux/cart/thunks';

export const slice = createSlice({
  name: 'products',
  initialState: adapter.getInitialState(),
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchProduct.fulfilled, (state, action) => {
        if (!action.payload.entities.products) return;
        adapter.upsertMany(state, action.payload.entities.products);
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        if (!action.payload.products) return;
        adapter.upsertMany(state, action.payload.products);
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        if (!action.payload.products) return;
        adapter.upsertMany(state, action.payload.products);
      })
      .addCase(thunks.unfavoriteProduct.fulfilled, (state, action) => {
        if (!action.payload) return;
        adapter.updateOne(state, { id: action.payload, changes: { favorite: false } });
      })
      .addCase(thunks.favoriteProduct.fulfilled, (state, action) => {
        if (!action.payload.product_id) return;
        adapter.updateOne(state, { id: action.payload.product_id, changes: { favorite: true } });
      })
      .addMatcher(
        isAnyOf(
          thunks.fetchProducts.fulfilled,
          thunks.fetchUserProducts.fulfilled,
          Orders.thunks.fetchOrders.fulfilled,
        ),
        (state, action) => {
          if (!action.payload.entities.products) return;
          adapter.upsertMany(state, action.payload.entities.products);
        },
      );
  },
});

const Products = {
  slice,
  thunks,
  selectors,
};
export default Products;
