export const capitalizeString = (string) => (string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : '');

export const humanize = (str) =>
  str
    ? str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g, '$& ')
        .replace(/^[a-z]/, (word) => word.toUpperCase())
    : 'N/A';
