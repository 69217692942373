import { useCallback, useState } from 'react';
import Button from 'antd/es/button';
import Menu from 'antd/es/menu';
import Typography from 'antd/es/typography';
import Modal from 'antd/es/modal';
import MenuOutlined from '@ant-design/icons/MenuOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMedia from 'react-use/lib/useMedia';
import { useSelector, useDispatch } from 'react-redux';
import { profileMenuSignedIn, profileMenu } from 'components/molecules/Header/MenuItems';
import Categories from 'redux/categories';
import Brands from 'redux/brands';
import { isSignedInSelector } from 'redux/auth/selectors';
import { authSlice } from 'redux/auth';
import Cart from 'redux/cart';
import queryString from 'query-string';
import Promotions from 'redux/promotions';
import './index.scss';

const BurgerMenu = ({ onCategoryPress }) => {
  const { t } = useTranslation(['burger-menu', 'molecules']);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState(false);
  const mobileView = useMedia('(max-width: 769px)');

  const isSignedIn = useSelector(isSignedInSelector);

  const mainCategories = useSelector(Categories.selectors.mainCategories);
  const singleCategory = useSelector(Categories.selectors.byId);
  const brands = useSelector(Brands.selectors.selectAll);
  const promotions = useSelector(Promotions.selectors.selectAll);

  const onchangeLanguage = useCallback(() => {
    if (i18n.language === 'ar') i18n.changeLanguage('en');
    else i18n.changeLanguage('ar');
  }, [i18n]);
  const toggleOpen = useCallback(() => setIsOpened(!isOpened), [isOpened]);

  const itemSelect = useCallback(
    ({ key }) => {
      toggleOpen();

      if (
        key === 'signin' ||
        key === 'signup' ||
        key === 'profile' ||
        key === 'edit-profile' ||
        key === 'about-us' ||
        key.toString().startsWith('promotion')
      )
        return;
      else if (key === 'logout') {
        dispatch(authSlice.actions.userLogout());
        dispatch(Cart.slice.actions.resetCart());
      } else if (key === 'language') onchangeLanguage();
      else if (onCategoryPress) {
        onCategoryPress(key);
      }
    },
    [dispatch, onCategoryPress, onchangeLanguage, toggleOpen],
  );

  const categoriesMenuRender = useCallback(
    (categoryId) => {
      const category = singleCategory(categoryId);
      const subCategories = category?.children;
      return (
        <Menu.SubMenu title={<Typography.Text>{category?.name}</Typography.Text>} key={category?.name}>
          {subCategories.map((subCategory) => (
            <Menu.ItemGroup
              title={
                <Button type="text" onClick={() => itemSelect({ key: subCategory.id })}>
                  <Typography.Text>{subCategory?.name}</Typography.Text>
                </Button>
              }
              className="subcategory-title"
              key={subCategory.id}
            >
              {subCategory.children?.map((item) => (
                <Menu.Item key={item.id} className="subcategory-menu-item">
                  <Button type="text" onClick={() => itemSelect({ key: item.id })}>
                    <Typography.Text>{item.name}</Typography.Text>
                  </Button>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          ))}
        </Menu.SubMenu>
      );
    },
    [itemSelect, singleCategory],
  );

  const brandsMenuRender = useCallback(
    () => (
      <Menu.SubMenu title={<Typography.Text>{t('molecules:header:brands')}</Typography.Text>}>
        {brands.map((brand) => (
          <Menu.ItemGroup
            title={
              <Link to={`/products?${queryString.stringify({ brand_id: brand.id })}`}>
                <Button type="text" onClick={toggleOpen}>
                  <Typography.Text>
                    {i18n.language === 'ar' && brand.name_ar ? brand.name_ar : brand.name_en}
                  </Typography.Text>
                </Button>
              </Link>
            }
            className="subcategory-title"
            key={brand.id}
          />
        ))}
      </Menu.SubMenu>
    ),
    [brands, i18n.language, t, toggleOpen],
  );

  const promotionsMenuRender = useCallback(
    () =>
      promotions?.map((promotion) => (
        <Menu.Item key={`promotion_${promotion.id}`}>
          <Link to={`/promotions/${promotion.id}`}>
            <Typography.Text>{promotion.name}</Typography.Text>
          </Link>
        </Menu.Item>
      )),
    [promotions],
  );

  const profileMenuRender = useCallback(() => {
    let profileMenuGroup = isSignedIn ? profileMenuSignedIn : profileMenu;
    return profileMenuGroup?.map((item) => (
      <Menu.Item key={item.label}>
        <Link to={item.link}>
          <Typography.Text>{t(`profile-menu.${item.label}`)}</Typography.Text>
        </Link>
      </Menu.Item>
    ));
  }, [isSignedIn, t]);

  const renderMenuOverlay = useCallback(
    () => (
      <Menu mode="inline" onClick={itemSelect} inlineIndent={18}>
        <Menu.ItemGroup key="categories" title={t('categories')} className="burger-section-title">
          {mainCategories.map((categoryId) => categoriesMenuRender(categoryId))}
          {brandsMenuRender()}
        </Menu.ItemGroup>
        <Menu.ItemGroup key="promotions" title={t('promotions')} className="burger-section-title">
          {promotionsMenuRender()}
        </Menu.ItemGroup>
        <Menu.ItemGroup key="profile" title={t('profile')} className="burger-section-title">
          {profileMenuRender()}
        </Menu.ItemGroup>
        <Menu.ItemGroup key="language" title={t('language')} className="burger-section-title">
          <Menu.Item key="language" icon={<GlobalOutlined />}>
            <Typography.Text>{t('languageDisplay')}</Typography.Text>
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="about-us" title={t('about-us')} className="burger-section-title">
          <Menu.Item key="about-us">
            <Link to="/about-us">
              <Typography.Text>{t('about-us')}</Typography.Text>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    ),
    [brandsMenuRender, categoriesMenuRender, itemSelect, mainCategories, profileMenuRender, promotionsMenuRender, t],
  );
  return (
    <div className="burger-menu-wrapper">
      <Button onClick={toggleOpen}>
        <MenuOutlined style={{ fontSize: 18 }} />
      </Button>
      <Modal
        visible={isOpened && mobileView}
        onCancel={toggleOpen}
        footer={null}
        bodyStyle={{ padding: '10px 0px' }}
        maskStyle={{ backgroundColor: 'transparent' }}
        style={{ float: i18n.dir() === 'ltr' ? 'left' : 'right' }}
        className="burger-menu-modal"
      >
        {renderMenuOverlay()}
      </Modal>
    </div>
  );
};
export default BurgerMenu;
