import { List } from 'antd';
import ProductCard from 'components/molecules/ProductCard';

const renderItem = (item) => (
  <List.Item>
    <ProductCard productId={item} />
  </List.Item>
);

const ProductsList = ({ products, loading, columns = 3 }) => {
  const listGrid = {
    gutter: 10,
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: columns,
    xxl: 4,
  };
  return <List grid={listGrid} dataSource={products} renderItem={renderItem} loading={loading} />;
};

export default ProductsList;
