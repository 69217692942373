import { List } from 'antd';
import OrdersItem from 'components/molecules/OrdersItem';

const renderItem = (item) => (
  <List.Item>
    <OrdersItem orderId={item} />
  </List.Item>
);

const OrdersList = ({ orders, loading }) => {
  return <List dataSource={orders} renderItem={renderItem} loading={loading} />;
};

export default OrdersList;
