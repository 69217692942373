import { Carousel, Space, Spin } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import ProductsList from 'components/organsims/ProductsList';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProduct, fetchProducts } from 'redux/products/thunks';
import { selectLatestProducts } from 'redux/products/selectors';
import { fetchPageSettings } from 'redux/pageSettings/thunks';
import { pageSettingsSelector } from 'redux/pageSettings/selectors';

import { recentyViewedSelector } from 'redux/auth/selectors.js';
import EntityPagination from 'components/atoms/Pagination/index.jsx';
import { unwrapResult } from '@reduxjs/toolkit';

import PerksSection from './components/PerksSection/index.jsx';
import './index.scss';

const HomePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('screens');
  const latestProducts = useSelector(selectLatestProducts)(4);
  const recentlyViewed = useSelector(recentyViewedSelector);
  const promotionsMeta = useSelector((state) => state._pagination[`products/fetchAll?with_promotion=true`]);

  const [promotionsProducts, setPromotionsProducts] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({ defaultPageSize: 8, total: 100 });
  const [loadingPromotions, setLoadingPromotions] = useState(false);
  const { top_slider_first, top_slider_second, top_slider_third, top_slider_fourth } =
    useSelector(pageSettingsSelector)('home_page') || {};
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(true);
    dispatch(fetchPageSettings({ params: { group: 'home_page' } })).then(() => setPageLoading(false));
  }, [dispatch]);

  useEffect(() => {
    recentlyViewed?.map((id) => dispatch(fetchProduct({ id })));
  }, [dispatch, recentlyViewed]);

  useEffect(() => {
    setLoadingPromotions(true);
    dispatch(fetchProducts({ params: { in_dedicated_page: false, per_page: 8 }, refresh: true }))
      .then(unwrapResult)
      .then((response) => {
        setPromotionsProducts(response.result);
        setLoadingPromotions(false);
        dispatch(fetchProducts({ params: { latest_to_oldest: true }, refresh: true }));
      });
  }, [dispatch]);

  const onPageChange = (page) => {
    setLoadingPromotions(true);
    dispatch(fetchProducts({ params: { in_dedicated_page: false, per_page: 8, page }, refresh: true }))
      .then(unwrapResult)
      .then((response) => {
        setPromotionsProducts(response.result);
        setLoadingPromotions(false);
      });
  };

  useEffect(() => {
    setPaginationInfo({ defaultPageSize: promotionsMeta?.per_page, total: promotionsMeta?.total_entries });
  }, [promotionsMeta]);

  return (
    <div className="homepage">
      {pageLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <Carousel className="homepage-carousel" autoplay>
          <img src={top_slider_first?.attachment} alt="carousel" />
          <img src={top_slider_second?.attachment} alt="carousel" />
          <img src={top_slider_third?.attachment} alt="carousel" />
          <img src={top_slider_fourth?.attachment} alt="carousel" />
        </Carousel>
      )}
      <div className="homepage-screen">
        <Space size="large" direction="vertical">
          <PerksSection />
          {promotionsProducts.length !== 0 ? (
            <BorderedSection title={t('homepage.featured.title')}>
              <div className="promotions-section">
                <ProductsList products={promotionsProducts} columns={4} loading={loadingPromotions} />
                <EntityPagination
                  onChange={onPageChange}
                  defaultPageSize={paginationInfo.defaultPageSize}
                  total={paginationInfo.total}
                />
              </div>
            </BorderedSection>
          ) : null}
          <BorderedSection title={t('homepage.newest.title')}>
            <ProductsList products={latestProducts} columns={4} />
          </BorderedSection>
          <BorderedSection title={t('homepage.recently-viewed.title')}>
            <ProductsList products={recentlyViewed} columns={4} />
          </BorderedSection>
        </Space>
      </div>
    </div>
  );
};

export default HomePage;
