import React, { useCallback } from 'react';
import { Card, Typography, Button, Divider, Col, Row, Space, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ItemCounter from 'components/atoms/ItemCounter';
import Price from 'components/atoms/Price';
import localizeNumber from 'utils/localizeNumber';
import AddToFavourites from 'components/atoms/AddToFavourites';
import { useDispatch, useSelector } from 'react-redux';
import Products from 'redux/products';
import { removeFromCart } from 'redux/cart/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { productCartCounter } from 'redux/cart/selectors';
import Brands from 'redux/brands';
import { Link } from 'react-router-dom';
import './index.scss';

const ProductPreview = ({ productId, isEditable, onCountChange, productOrder }) => {
  const dispatch = useDispatch();
  const currentProduct = useSelector((state) => Products.selectors.selectById(state, productId));
  let product = currentProduct;
  if (productOrder) product = productOrder;

  const productCount = useSelector(productCartCounter)(productId);
  const { t, i18n } = useTranslation('product-preview');

  const onRemovePress = () => {
    dispatch(removeFromCart({ order_product: { product_id: productId, _destroy: 'true' } }))
      .then(unwrapResult)
      .catch((error) => {
        Modal.error({
          content: (
            <>
              {error?.errors?.map((errorItem) => (
                <Typography.Text>{errorItem}</Typography.Text>
              ))}
            </>
          ),
        });
      });
  };

  const _onCountChange = useCallback(
    (count) => {
      onCountChange(productId, count);
    },
    [onCountChange, productId],
  );

  const { id, title, price, images, brand, stock, quantity, specifications = [] } = product;
  const brandObj = useSelector((state) => Brands.selectors.selectById(state, brand)) || {};

  return (
    <div className="product-preview-wrapper">
      <Card hoverable>
        <Link to={`/products/${productId || id}`} className="product-link"></Link>
        <Row gutter={[40, 20]}>
          <Col sm={24} md={8}>
            <img src={images?.[0]?.versions.medium} alt="product" />
          </Col>
          <Col sm={24} md={8}>
            <Typography.Title type="secondary" level={2} strong>
              {title}
            </Typography.Title>
            <Space size="large" direction="vertical">
              <div className="price-container">
                {!product?.checkout_price && product?.promotion && product?.price !== product?.price_after_promotion ? (
                  <Space>
                    <Price text={`${localizeNumber(product?.price_after_promotion)} ${t('currency')}`} />
                    <Price text={`${localizeNumber(price)} ${t('currency')}`} crossed />
                  </Space>
                ) : (
                  <Price text={`${localizeNumber(product?.checkout_price || price)} ${t('currency')}`} />
                )}
              </div>
              <Space direction="vertical">
                <Typography.Text type="secondary">
                  {t('brand', { productBrand: brandObj?.name || brand?.name })}
                </Typography.Text>
                {quantity && <Typography.Text type="secondary">{t('quantity', { quantity })}</Typography.Text>}
                {['refunded', 'partially_refunded'].includes(product?.order_status) && (
                  <Typography.Text type="secondary">
                    {t('refunded_quantity', { refunded_quantity: product?.refunded_quantity })}
                  </Typography.Text>
                )}
                {/* {specifications.map((spec) => (
                  <Typography.Text type="secondary" key={spec.id}>
                    {spec[`key_${i18n.language}`]}: {spec[`value_${i18n.language}`]}
                  </Typography.Text>
                ))} */}
              </Space>
            </Space>
          </Col>
          <Col sm={24} md={8} lg={8} xl={6}>
            {isEditable && (
              <Space direction="vertical" align="center">
                <Row className="product-item-counter">
                  <ItemCounter
                    stockMaxValue={stock}
                    orderMaxValue={currentProduct.maximum_number_per_order}
                    defaultValue={productCount}
                    onValueChange={_onCountChange}
                    onRemoveProduct={onRemovePress}
                  />
                </Row>
                {stock <= 3 && (
                  <Row>
                    <Typography.Text type="danger">{t('stock', { stock: stock })}</Typography.Text>
                  </Row>
                )}
              </Space>
            )}
          </Col>
        </Row>

        {isEditable && (
          <div className="product-preview-actions">
            <Divider />
            <AddToFavourites product={currentProduct} />
            <Divider type="vertical" />
            <Button type="text" onClick={onRemovePress}>
              {t('remove')}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

ProductPreview.propTypes = {
  isEditable: PropTypes.bool,
  isFavorite: PropTypes.bool,
  product: PropTypes.object,
  count: PropTypes.number,
  onCountChange: PropTypes.func,
  onFavoritePress: PropTypes.func,
  onRemovePress: PropTypes.func,
};
export default ProductPreview;
