import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BorderedSection from 'components/organsims/BorderedSection';
import ImageGallery from 'react-image-gallery';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Space, Typography, Skeleton } from 'antd';
import AddToFavorites from 'components/atoms/AddToFavourites';
import { useTranslation } from 'react-i18next';
import AddToCart from 'components/atoms/AddToCart';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import useMedia from 'react-use/lib/useMedia';
import { fetchProduct } from 'redux/products/thunks';
import Products from 'redux/products';
import Brands from 'redux/brands';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import './index.scss';
import Price from 'components/atoms/Price';
import localizeNumber from 'utils/localizeNumber';
import isEmpty from 'lodash/isEmpty';

const ProductDetail = ({ title, value }) => (
  <Typography.Text className="product-detail">
    {title}: {value}
  </Typography.Text>
);

const SingleProduct = ({ productId }) => {
  const product = useSelector(Products.selectors.selectSingleProduct)(productId);
  const history = useHistory();
  const { id, specifications = [], brand, images = [], stock, price = 0, price_after_promotion = 0 } = product;
  const { name: brandName } = useSelector((state) => Brands.selectors.selectById(state, brand)) || {};
  const dispatch = useDispatch();
  const isSmall = useMedia('(max-width: 576px)');
  const { t, i18n } = useTranslation('molecules');
  const { t: globalTranslation } = useTranslation('global');
  const [fullScreenMode, toggleFullScreenMode] = useState(false);
  const handleFullScreenMode = () => {
    toggleFullScreenMode((previousFullScreenMode) => !previousFullScreenMode);
  };
  useEffect(() => {
    dispatch(fetchProduct({ id: productId }))
      .then(unwrapResult)
      .catch((error) => {
        if (error.status === 404) history.replace('/404');
      });
  }, [dispatch, history, productId]);

  const imageUrlMappings = images?.map((image) => image.original);

  return (
    <div className="single-product">
      <BorderedSection title={product[`title_${i18n.language}`]}>
        {isEmpty(product) ? (
          <Skeleton active paragraph={{ rows: 4 }} avatar={{ shape: 'square', size: 60 }} />
        ) : (
          <Row gutter={[40, 40]}>
            <Col sm={24} md={12} lg={12} className="image-gallery-wrapper">
              {fullScreenMode && <Lightbox images={imageUrlMappings || []} onClose={handleFullScreenMode} />}
              <ImageGallery
                showNav={false}
                items={images}
                thumbnailPosition={isSmall ? 'bottom' : 'left'}
                showPlayButton={false}
                onClick={handleFullScreenMode}
                renderFullscreenButton={(onClick, isFullScreen) => (
                  <Button
                    onClick={onClick}
                    type="link"
                    icon={!isFullScreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                  ></Button>
                )}
              />
            </Col>
            <Col sm={24} md={12} lg={12} className="product-details-wrapper">
              <Space size="large" direction="vertical">
                <Typography.Text>{product[`description_${i18n.language}`]}</Typography.Text>
                {stock <= 3 && stock > 0 && (
                  <Row>
                    <Typography.Text type="danger">{t('single_product.stock', { stock })}</Typography.Text>
                  </Row>
                )}
                {stock === 0 && (
                  <Row>
                    <Typography.Text type="danger">{t('single_product.out_stock')}</Typography.Text>
                  </Row>
                )}
                <AddToFavorites product={product} />
                <div>
                  <ProductDetail title={t('single_product.brand')} value={brandName} />
                </div>
                {!isEmpty(specifications) && (
                  <div>
                    <Typography.Title level={4}>{t('single_product.specs')}</Typography.Title>
                    {specifications.map((spec) => (
                      <ProductDetail
                        key={spec.id}
                        title={spec[`key_${i18n.language}`]}
                        value={spec[`value_${i18n.language}`]}
                      />
                    ))}
                  </div>
                )}
                <div>
                  {product?.promotion && product?.price !== product?.price_after_promotion ? (
                    <Space>
                      <Price text={`${localizeNumber(price_after_promotion)} ${globalTranslation('currency')}`} />
                      <Price text={`${localizeNumber(price)} ${globalTranslation('currency')}`} crossed />
                    </Space>
                  ) : (
                    <Price text={`${localizeNumber(price)} ${globalTranslation('currency')}`} />
                  )}
                </div>
                <AddToCart id={id} isAvailable={stock !== 0} />
              </Space>
            </Col>
          </Row>
        )}
      </BorderedSection>
    </div>
  );
};

export default SingleProduct;
