import { useCallback, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

const useFiltersParams = ({ type }) => {
  const { search } = useLocation();

  const { slug } = useParams();
  const initialFilters = useCallback(() => {
    switch (type) {
      case 'special-offers':
        return { in_dedicated_page: false, featured_first: true };
      case 'promotions':
        return { promotion_slug: slug, featured_first: true };
      default:
        return { featured_first: true };
    }
  }, [slug, type]);

  const [filterParams, setFilterParams] = useState({});

  const isFilteredByBrand = () => queryString.parse(search).brand_id && !queryString.parse(search).category_id;

  const getAppliedFilters = useCallback(
    (page) => {
      let searchParams = queryString.parse(search);
      //had to add the initialFilters here as when navigating between different tabs non-updated filterParams resets api calls
      return {
        ...initialFilters(),
        ...filterParams,
        page,
        query: searchParams?.search,
        category_id: searchParams?.category_id || filterParams.category_id,
        brand_id: searchParams?.brand_id || filterParams.brand_id,
      };
    },
    [filterParams, initialFilters, search],
  );

  const updateFilterParams = useCallback((newParams) => {
    setFilterParams((params) => ({ ...params, ...newParams }));
  }, []);

  const resetFilters = useCallback(() => {
    setFilterParams({});
  }, []);

  return { initialFilters, updateFilterParams, getAppliedFilters, resetFilters, isFilteredByBrand };
};

export default useFiltersParams;
