import { Col, Row, List, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import ProductPreview from 'components/molecules/ProductPreview';
import BorderedSection from 'components/organsims/BorderedSection';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OrderSummary from 'components/organsims/OrderSummary';
import { cartAdapterSelectors, cartErrors, cartLoading } from 'redux/cart/selectors';
import { addToCart } from 'redux/cart/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import Modal from 'antd/es/modal/Modal';
import { useHistory } from 'react-router-dom';
import './index.scss';
import Cart from 'redux/cart';

const renderItem = (item) => <RenderItem productId={item} />;

const RenderItem = ({ productId }) => {
  const { t } = useTranslation('screens');
  const dispatch = useDispatch();
  const [opend, setOpend] = useState(false);

  const onCountChange = useCallback(
    (productId, count) => {
      dispatch(addToCart({ order_product: { product_id: productId, quantity: count, _destroy: 'false' } }))
        .then(unwrapResult)
        .catch((error) => {
          Modal.error({
            content: (
              <>
                {Object.keys(error).map((errorKey) => (
                  <Typography.Text>{error[errorKey]}</Typography.Text>
                ))}
              </>
            ),
            okText: t('checkout.continue'),
            onOk: () => {
              setOpend(false);
            },
          });
        });
    },
    [dispatch],
  );

  return (
    <div className="product-preview">
      <ProductPreview onCountChange={onCountChange} productId={productId} isEditable />
    </div>
  );
};
const CartScreen = () => {
  const { t } = useTranslation('screens');
  const cartItems = useSelector(cartAdapterSelectors.selectIds);
  const loading = useSelector(cartLoading);
  const errors = useSelector(cartErrors);
  const history = useHistory();
  const [opend, setOpend] = useState(false);

  useEffect(() => {
    if (!loading) {
      setOpend(true);
      if (opend && errors && errors.sap_sync) {
        setOpend(true);
        Modal.error({
          content: (
            <>
              {Object.keys(errors).map((errorKey) => (
                <Typography.Text>{errors[errorKey]}</Typography.Text>
              ))}
            </>
          ),
          okText: t('checkout.continue'),
          onOk: () => {
            setOpend(false);
            history.push('/');
          },
        });
      }
    }
  }, [errors, opend, loading, t, history]);

  return (
    <Row className="cart" gutter={[20, 20]}>
      <Col xs={24} sm={24} md={24} lg={17} xl={17} xxl={20}>
        <BorderedSection title={t('cart.cart_list.title')}>
          <List renderItem={renderItem} dataSource={cartItems} loading={loading} />
        </BorderedSection>
      </Col>
      <Col className="cart-summary" xs={24} sm={24} md={24} lg={7} xl={7} xxl={4}>
        {!loading && <OrderSummary />}
      </Col>
    </Row>
  );
};

export default CartScreen;
