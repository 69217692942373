import { createSelector } from '@reduxjs/toolkit';
import values from 'lodash/values';
import memoize from 'lodash/memoize';
import reduce from 'lodash/reduce';
import Products from 'redux/products';
import { cartAdapter } from './adapter';

export const shippingFees = (store) => store.cart.shippingFees;
export const totalCartPrice = (store) => store.cart.totalCart;
export const totalCheckoutPrice = (store) => store.cart.totalCheckout;

export const cartCounter = createSelector(
  (store) => store.cart.entities,
  (items) =>
    reduce(
      items,
      function (result, value) {
        const productCount = 1 * value.quantity;
        return result + productCount;
      },
      0,
    ),
);
export const productCartCounter = createSelector(
  (store) => store.cart.entities,
  (items) => memoize((product) => values(items).find((item) => item.id === product)?.quantity),
);
export const cartLoading = createSelector(
  (store) => store.cart,
  (cart) => cart.loading,
);
export const cartErrors = createSelector(
  (store) => store.cart,
  (cart) => cart.errors,
);
export const cartAdapterSelectors = cartAdapter.getSelectors((state) => state.cart);

export const itemsPrice = createSelector(
  Products.selectors.selectEntities,
  cartAdapterSelectors.selectEntities,
  (products, cart) => {
    const sum = reduce(
      cart,
      function (result, value, key) {
        const productSum =
          value.quantity * (products[key].promotion ? products[key].price_after_promotion : products[key].price);
        return result + productSum;
      },
      0,
    );
    return sum;
  },
);

export const addressCheck = createSelector(
  (store) => store.cart.entities,
  (items) => values(items),
);
