import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Affix from 'antd/lib/affix';
import { useTranslation } from 'react-i18next';
import { fetchCategories } from 'redux/categories/thunks';
import { fetchBrands } from 'redux/brands/thunks';
import ProfileHeader from './components/ProfileHeader';
import CategoriesHeader from './components/CategoriesHeader';
import SearchBar from 'components/molecules/SearchBar';
import HideController from 'components/molecules/HideController';
import { fetchPromotions } from 'redux/promotions/thunks';
import './index.scss';

const TopHeader = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchBrands({ params: { show_all: true } }));
    dispatch(fetchPromotions({ params: { show_all: true, dedicated_page: true } }));
  }, [dispatch, i18n.language]);

  const onSearchPress = useCallback(
    (searchKey) => {
      const search = queryString.stringify({ search: searchKey });
      location.search = search;
      history.push('/products?' + location.search);
    },
    [history, location],
  );
  const onCategoryPress = useCallback(
    (category_id) => {
      const category = queryString.stringify({ category_id: category_id });
      location.search = category;
      history.push('/products?' + location.search);
    },
    [history, location],
  );

  return (
    <Affix offsetTop={0.00000001}>
      <div className="site-header">
        <HideController>
          <ProfileHeader />
        </HideController>
        <HideController>
          <SearchBar onSearchPress={onSearchPress} onCategoryPress={onCategoryPress} />
        </HideController>
        <HideController toBack move="-110px">
          <CategoriesHeader onCategoryPress={onCategoryPress} />
        </HideController>
      </div>
    </Affix>
  );
};

export default TopHeader;
