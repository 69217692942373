import { useState, useCallback } from 'react';
import { AutoComplete, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { SearchOutlined } from '@ant-design/icons';
import Logo from 'assets/images/Abdelaziz-stores-logo.jpg';
import CartIcon from 'components/molecules/CartIcon';
import { useDispatch } from 'react-redux';
import { fetchProducts } from 'redux/products/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import BurgerMenu from 'components/molecules/BurgerMenu';
import SearchResults from './components/SearchResults';
import './index.scss';

const SearchBar = ({ onSearchPress, onCategoryPress }) => {
  const { t } = useTranslation('search-bar');
  const [searchKey, setSearchKey] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const mobileView = useMedia('(max-width: 769px)');
  const [searchOptions, setSearchOptions] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const searchResults = useCallback(
    (query) => {
      dispatch(fetchProducts({ params: { query }, refresh: true }))
        .then(unwrapResult)
        .then((response) => {
          setSearchOptions(
            response?.result
              ? response?.result.map((id) => {
                  return {
                    value: id,
                    label: <SearchResults id={id} />,
                  };
                })
              : [],
          );
        });
    },
    [dispatch],
  );

  const onSearchIconClick = useCallback(() => {
    setIsSearchVisible(!isSearchVisible);
  }, [isSearchVisible]);

  const onSearchSubmit = useCallback(() => {
    if (isSearchVisible) setIsSearchVisible(false);
    if (searchKey.length !== 0) onSearchPress(searchKey);
  }, [isSearchVisible, onSearchPress, searchKey]);

  const onOptionSelect = useCallback(
    (value, _) => {
      history.push(`/products/${value}`);
      setIsSearchVisible(false);
      setSearchKey('');
    },
    [history],
  );

  const delayedSearch = debounce(
    useCallback((value) => searchResults(value), [searchResults]),
    500,
  );

  const onChange = useCallback((event) => {
    setSearchKey(event?.target?.value);
  }, []);

  return (
    <div className="search-bar-wrapper">
      <div>
        <BurgerMenu onCategoryPress={onCategoryPress} />
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <AutoComplete
        options={searchOptions}
        onSelect={onOptionSelect}
        onSearch={delayedSearch}
        value={searchKey}
        className="search-bar"
      >
        <Input.Search
          placeholder={t('placeholder')}
          onSearch={onSearchSubmit}
          enterButton
          size="large"
          onChange={onChange}
        />
      </AutoComplete>
      <Modal
        visible={isSearchVisible && mobileView}
        closable={false}
        footer={null}
        onCancel={() => setIsSearchVisible(false)}
        className="search-modal"
      >
        <AutoComplete value={searchKey} options={searchOptions} onSelect={onOptionSelect} onSearch={delayedSearch}>
          <Input.Search
            placeholder={t('placeholder')}
            onSearch={onSearchSubmit}
            enterButton
            size="small"
            onChange={onChange}
          />
        </AutoComplete>
      </Modal>
      <div>
        <SearchOutlined className="search-icon" onClick={onSearchIconClick} />
        <CartIcon link="/cart" />
      </div>
    </div>
  );
};
SearchBar.PropType = {
  onSearchPress: PropTypes.func,
  numberOfItems: PropTypes.number,
};
export default SearchBar;
