import { Typography } from 'antd';
import PropTypes from 'prop-types';

import './index.scss';

const Price = ({ text, crossed, discounted }) => (
  <Typography.Text className={discounted ? 'price discounted' : 'price'} delete={crossed}>
    {text}
  </Typography.Text>
);

Price.PropType = {
  text: PropTypes.string.required,
  crossed: PropTypes.bool,
  discounted: PropTypes.string.required,
};

export default Price;
