import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import values from 'lodash/values';
import adapter from './adapter';

const byId = createSelector(adapter.getSelectors((store) => store['brands']).selectEntities, (items) =>
  memoize((id) =>
    values(items)
      .filter((item) => item.id === id)
      .map((item) => item),
  ),
);

const getFeaturedBrands = createSelector(adapter.getSelectors((store) => store['brands']).selectEntities, (items) =>
  values(items).filter((item) => item.featured),
);

const selectors = {
  byId,
  getFeaturedBrands,
  ...adapter.getSelectors((state) => state['brands']),
};
export default selectors;
