import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductsList from 'components/organsims/ProductsList';
import { fetchUserProducts } from 'redux/products/thunks';
import Products from 'redux/products';
import EntityPagination from 'components/atoms/Pagination';

const FavoritesTab = () => {
  const dispatch = useDispatch();
  const favoriteProducts = useSelector(Products.selectors.selectFavorites);
  const [displayedPage, setDisplayPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState({ defaultPageSize: 10, total: 100 });
  const productsMeta = useSelector((state) => state._pagination[`products/fetchUserProducts?favorites=true`]);

  useEffect(() => {
    dispatch(fetchUserProducts({ params: { favorites: true, page: displayedPage }, refresh: true }));
  }, [dispatch, displayedPage]);

  useEffect(() => {
    setPaginationInfo({ defaultPageSize: productsMeta?.per_page, total: productsMeta?.total_entries });
  }, [productsMeta]);

  return (
    <>
      <ProductsList products={favoriteProducts} />
      <EntityPagination
        onChange={setDisplayPage}
        defaultPageSize={paginationInfo.defaultPageSize}
        total={paginationInfo.total}
      />
    </>
  );
};
export default FavoritesTab;
