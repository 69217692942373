import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DetailsItem from '../../DetailsItem';
import './index.scss';

const PersonalTab = () => {
  const { t } = useTranslation('screens');
  const user = useSelector((store) => store.auth.currentUser);

  return (
    <div className="personal-tab">
      <Space direction="vertical">
        <DetailsItem title={t('profile.personal.first_name')} value={user.first_name} />
        <DetailsItem title={t('profile.personal.last_name')} value={user.last_name} />
        <DetailsItem title={t('profile.personal.email')} value={user.email} />
        <DetailsItem title={t('profile.personal.mobile')} value={user.phone_number} />
        <Link to="/change-password">
          <Button className="change-password" type="link">
            {t('profile.personal.change_password')}
          </Button>
        </Link>
      </Space>
    </div>
  );
};
export default PersonalTab;
