import { useRef, useMemo, useCallback } from 'react';
import { Carousel, List } from 'antd';
import { LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import chunk from 'lodash/chunk';
import { useMedia } from 'react-use';
import ProductCard from 'components/molecules/ProductCard';
import './index.scss';

const IconToRender = {
  rtl: RightSquareOutlined,
  ltr: LeftSquareOutlined,
};
const ProductSlider = ({ products }) => {
  const carouselRef = useRef();
  const { i18n } = useTranslation();
  const langDir = i18n.dir();
  const onPreviousPress = () => carouselRef.current?.prev();
  const onNextPress = () => carouselRef.current?.next();
  const ipadView = useMedia('(max-width: 768px)');
  const mobileView = useMedia('(max-width: 576px');

  const renderProducts = useCallback(() => {
    const dividedProducts = chunk(products, ipadView ? (mobileView ? 1 : 2) : 4);
    return dividedProducts.map((singleGroup) => (
      <List
        key={singleGroup[0]}
        grid={{
          gutter: 8,
          column: ipadView ? (mobileView ? 1 : 2) : 4,
        }}
        dataSource={singleGroup}
        className="slider-children-wrapper"
        renderItem={(product) => (
          <List.Item>
            <ProductCard productId={product} />
          </List.Item>
        )}
      />
    ));
  }, [products, ipadView, mobileView]);

  const renderBeforeArrow = useMemo(() => {
    const ArrowDirection = IconToRender[langDir];
    return <ArrowDirection onClick={onPreviousPress} />;
  }, [langDir]);

  const renderAfterArrow = useMemo(() => {
    const ArrowDirection = IconToRender[langDir === 'ltr' ? 'rtl' : 'ltr'];
    return <ArrowDirection onClick={onNextPress} />;
  }, [langDir]);

  return (
    <div className="slider-wrapper">
      {renderBeforeArrow}
      <Carousel ref={carouselRef}>{renderProducts()}</Carousel>
      {renderAfterArrow}
    </div>
  );
};

export default ProductSlider;
