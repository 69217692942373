import { Button, Row, Col } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

const PaymentFailure = () => {
  const { t } = useTranslation('screens');
  const history = useHistory();

  const onBack = () => {
    history.push('/cart');
  };
  return (
    <BorderedSection
      title={t('payment_failure.title')}
      description={t('payment_failure.description')}
      extraIconTitle={<CloseCircleOutlined />}
      type="danger"
    >
      <Row>
        <Col xs={24} sm={24} m={12} lg={12} xl={12} xxl={12} className="button-wrapper">
          <Button type="primary" onClick={onBack}>
            {t('payment_failure.back')}
          </Button>
        </Col>
      </Row>
    </BorderedSection>
  );
};

export default PaymentFailure;
