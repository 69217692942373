import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Divider, Button } from 'antd';
import { GlobalOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DropDownMenu from 'components/molecules/DropDown';
import { isSignedInSelector } from 'redux/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { authSlice } from 'redux/auth';
import './index.scss';
import Cart from 'redux/cart';

const ProfileHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('authentication');
  const { t: globalTranslation } = useTranslation('global');
  const { t: moleculeTranslation } = useTranslation('molecules');

  const { i18n } = useTranslation();
  const isSignedIn = useSelector(isSignedInSelector);
  const user = useSelector((store) => store.auth.currentUser);

  const onLanguageChange = useCallback(() => {
    if (i18n.language === 'ar') i18n.changeLanguage('en');
    else i18n.changeLanguage('ar');
  }, [i18n]);

  const onDropDownClick = ({ key }) => {
    if (key === 'logout') {
      dispatch(authSlice.actions.userLogout());
      dispatch(Cart.slice.actions.resetCart());
    }
  };

  return (
    <div className="profile-header">
      {isSignedIn ? (
        <DropDownMenu
          items={[
            { label: moleculeTranslation('header.profile_dropdown.profile'), link: '/profile/' },
            { label: moleculeTranslation('header.profile_dropdown.edit_profile'), link: '/edit-profile' },
            { key: 'logout', label: moleculeTranslation('header.profile_dropdown.logout'), link: '/' },
          ]}
          onClick={onDropDownClick}
        >
          <Button type="text">
            <UserOutlined className="profile-menu-icon" />
            <Typography.Text>{user.first_name}</Typography.Text>
          </Button>
        </DropDownMenu>
      ) : (
        <>
          <Link to="/auth/signin">
            <Button type="text" icon={<UserOutlined className="profile-icon" />}>
              {t('signin')}
            </Button>
          </Link>
          <Divider type="vertical" />
          <Link to="/auth/signup">
            <Button type="text" icon={<UserOutlined className="profile-icon" />}>
              {t('signup')}
            </Button>
          </Link>
        </>
      )}
      <Divider type="vertical" />
      <Button type="text" onClick={onLanguageChange}>
        <GlobalOutlined className="language-icon" />
        <Typography.Text>{globalTranslation('language')}</Typography.Text>
      </Button>
    </div>
  );
};
export default ProfileHeader;
