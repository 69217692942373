import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import { useTranslation } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import 'translation/i18n';

import store, { persistor } from './redux/store';
import { history } from './router';
import Router from './router';
import { Helmet } from 'react-helmet';
import './index.scss';

const Root = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || 'en';
  const direction = i18n.dir(currentLanguage);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Provider store={store}>
      <Helmet>
        <title>Abdul Aziz Stores | أسواق شارع عبد العزيز</title>
        <meta name="title" content="Abdul Aziz Stores | أسواق شارع عبد العزيز" />
        <meta name="description" content="عالم متنوع من الأجهزه الكهربائية و المنزلية و مستلزمات المنزلية" />
        <meta name="image" content="https://abdulaziz-stores.com/static/media/Abdelaziz-stores-logo.ed2427d8.png" />
        <meta name="type" content="website" />
        <meta property="og:title" content="Abdul Aziz Stores | أسواق شارع عبد العزيز" />
        <meta property="og:description" content="عالم متنوع من الأجهزه الكهربائية و المنزلية و مستلزمات المنزلية" />
        <link rel="apple-touch-icon" sizes="180x180" href="Blue logo New.svg" />
        <link rel="icon" type="image/png" href="Abdelaziz-stores-logo.jpg" sizes="16x16" />

        <meta
          property="og:image"
          content="https://abdulaziz-stores.com/static/media/Abdelaziz-stores-logo.ed2427d8.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="200" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ConfigProvider locale={currentLanguage === 'ar' ? arEG : enUS} direction={direction}>
            <Router />
          </ConfigProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
