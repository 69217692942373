import { useEffect, useState } from 'react';
import { Layout, Modal, Typography } from 'antd';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from 'components/molecules/Header';
import Footer from 'components/molecules/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart } from 'redux/cart/thunks';
import { isSignedInSelector } from 'redux/auth/selectors';
import './index.scss';
import { cartErrors, cartLoading } from 'redux/cart/selectors';
import Cart from 'redux/cart';

const DefaultLayout = ({ children }) => {
  const { t } = useTranslation('screens');
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(cartLoading);
  const errors = useSelector(cartErrors);
  const history = useHistory();
  const [opend, setOpend] = useState(false);
  const [errorDisplayed, setErrorDisplayed] = useState(false);

  useEffect(() => {
    if (!opend && !loading && !errorDisplayed && errors) {
      setOpend(true);
      Modal.error({
        content: (
          <>
            {Object.keys(errors).map((errorKey) => (
              <Typography.Text>{errors[errorKey]}</Typography.Text>
            ))}
          </>
        ),
        okText: t('checkout.continue'),
        onOk: () => {
          // if (location.pathname === '/')
          dispatch(Cart.slice.actions.resetErrors());
          setErrorDisplayed(true);
          setOpend(false);
          history.push('/');
        },
      });
    }
  }, [errors]);

  const isSignedIn = useSelector(isSignedInSelector);

  useEffect(() => {
    if (isSignedIn && location.pathname !== '/complete-payment') dispatch(fetchCart());
  }, [dispatch, isSignedIn, location.pathname]);

  return (
    <Layout>
      <Header />
      <Layout.Content>
        <TransitionGroup>
          <CSSTransition key={location} timeout={1000} classNames="fade">
            <div className="site-layout">{children}</div>
          </CSSTransition>
        </TransitionGroup>
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default DefaultLayout;
