import { useCallback, useEffect, useState } from 'react';
import { List, Space } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import IconText from 'components/atoms/IconText';
import AddressItem from '../../molecules/AddressItem';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Addresses from 'redux/addresses';
import { fetchAddresses } from 'redux/addresses/thunks';
import './index.scss';

const AddressesTab = ({ isEditable, onPickAddress }) => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const addressesIds = useSelector(Addresses.selectors.selectIds);

  const dispatch = useDispatch();

  const onAddAddress = useCallback(() => {
    history.push('/address');
  }, [history]);

  const renderItem = useCallback(
    (item) => (
      <div className="addresses-list">
        <AddressItem addressId={item} isEditable={isEditable} onPickAddress={onPickAddress} />
      </div>
    ),
    [isEditable, onPickAddress],
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAddresses({ params: {} }))
      .then(() => {})
      .finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <Space direction="vertical" className="address-tab" size="middle">
      <List dataSource={addressesIds} renderItem={renderItem} loading={loading} />
      <IconText
        textUnderlined
        text={t('profile.addresses.add_address')}
        size={10}
        iconComponent={<PlusSquareOutlined />}
        onIconClick={onAddAddress}
      />
    </Space>
  );
};

export default AddressesTab;
