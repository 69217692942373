import { Col, Row, Typography } from 'antd';
import './index.scss';

const OrderDetails = ({ title, details }) => (
  <Row className="order-details-item">
    <Col xs={24} sm={24} m={6} lg={6} xl={4} xxl={4}>
      <Typography.Text strong>{title}</Typography.Text>
    </Col>
    <Col xs={24} sm={24} m={16} lg={16} xl={16} xxl={16}>
      {details}
    </Col>
  </Row>
);

export default OrderDetails;
