import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

import { isSignedInSelector } from 'redux/auth/selectors';
import PrivateRoute from './PrivateRoute';

import SignIn from 'screens/Authentication/Signin';
import SignUp from 'screens/Authentication/Signup';
import ForgotPassword from 'screens/Authentication/ForgotPassword';
import ResetPassword from 'screens/Authentication/ResetPassword';
import ChangePassword from 'screens/ChangePassword';
import Cart from 'screens/Guest/Cart';
import HomePage from 'screens/Guest/Homepage';
import AboutUs from 'screens/Guest/AboutUs';
import Products from 'screens/Guest/Products';

import Product from 'screens/Guest/Product';
import Profile from 'screens/Profile';

import Checkout from 'screens/Checkout';
import EditProfile from 'screens/EditProfile';
import Payment from 'screens/Payment';
import OTP from 'screens/Authentication/OTP';
import AddressScreen from 'screens/AddressScreen';
import TermsConditions from 'screens/Guest/TermsConditions';

const Guest = () => {
  const isSignedIn = useSelector(isSignedInSelector);
  return (
    <Switch>
      <PrivateRoute exact path="/profile/:tab(personal|addresses|favorites|orders)?" component={Profile} />
      <PrivateRoute exact path="/checkout" component={Checkout} />
      <PrivateRoute exact path="/address/:addressId?" component={AddressScreen} />
      <PrivateRoute path="/change-password" component={ChangePassword} />
      <PrivateRoute exact path="/edit-profile" component={EditProfile} />
      <PrivateRoute path="/complete-payment" component={Payment} />

      <Route exact path="/cart" component={Cart} />
      <Route exact path="/products/:productId" component={Product} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/special-offers" component={() => <Products type="special-offers" />} />
      <Route exact path="/promotions/:slug" component={() => <Products type="promotions" />} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/terms-conditions" component={TermsConditions} />

      <Route path="/auth/:path?(signin|signup|forgot_password|reset_password/.+|otp_confirmation)">
        <Switch>
          {isSignedIn && <Redirect to="/" />}
          <Route exact path="/auth/signin" component={SignIn} />
          <Route exact path="/auth/signup" component={SignUp} />
          <Route exact path="/auth/forgot_password" component={ForgotPassword} />
          <Route path="/auth/reset_password/:token" component={ResetPassword} />
          <Route exact path="/auth/otp_confirmation" component={OTP} />
        </Switch>
      </Route>

      <Route exact path="/" component={HomePage} />

      <Route
        path="*"
        component={() => (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            }
          />
        )}
      />
    </Switch>
  );
};

export default Guest;
