import { useRef } from 'react';
import { Button, Divider, Modal, Space, Typography } from 'antd';
import BorderedSection from 'components/organsims/BorderedSection';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isSignedInSelector } from 'redux/auth/selectors';
import { cartCounter, itemsPrice, shippingFees, totalCartPrice, totalCheckoutPrice } from 'redux/cart/selectors';
import localizeNumber from 'utils/localizeNumber';
import { fetchOrders } from 'redux/orders/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import RatingModal from 'components/molecules/RatingModal';
import './index.scss';

const OrderSummary = ({ isCheckout }) => {
  const ratingModalRef = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('screens');
  const { t: globalTranslation } = useTranslation('global');
  const { t: authenticationTranslation } = useTranslation('authentication');
  const totalSum = useSelector(itemsPrice);
  const count = useSelector(cartCounter);
  const shippingFeesAmount = useSelector(shippingFees);
  const totalCart = useSelector(totalCartPrice);
  const totalCheckout = useSelector(totalCheckoutPrice);

  // const checkoutPrice = useSelector((state) => state.payments.checkoutPrice);

  const isSignedIn = useSelector(isSignedInSelector);

  const onContinue = () => {
    history.push('/');
  };

  const onPayClick = () => {
    if (isSignedIn) {
      dispatch(fetchOrders({ params: { unrated_only: true }, refresh: true }))
        .then(unwrapResult)
        .then((payload) => {
          if (!isEmpty(payload.result)) {
            ratingModalRef.current?.openModal(payload.result);
          } else if (count !== 0) history.push('/checkout');
        });
    } else {
      Modal.confirm({
        title: t('cart.summary.feedback.on_pay_not_logged_in.title'),
        content: t('cart.summary.feedback.on_pay_not_logged_in.content'),
        okText: authenticationTranslation('signup'),
        cancelText: authenticationTranslation('signin'),
        onOk: () => {
          history.push('/auth/signup');
        },
        onCancel: () => {
          history.push('/auth/signin');
        },
      });
    }
  };

  return (
    <div className="order-summary">
      <BorderedSection title={t('cart.summary.title')} level={4}>
        <Space size="middle" direction="vertical">
          <Space size="large" direction="horizontal">
            <Typography.Text strong>{t('cart.summary.price', { count })}</Typography.Text>
            <Typography.Text strong>
              {localizeNumber(totalCart || 0)} {globalTranslation('currency')}
            </Typography.Text>
          </Space>

          <Divider />

          {shippingFeesAmount ? (
            <Space size="large" direction="horizontal">
              <Typography.Text strong>{t('cart.summary.delivery_fees')}</Typography.Text>
              <Typography.Text strong>
                {localizeNumber(shippingFeesAmount)} {globalTranslation('currency')}
              </Typography.Text>
            </Space>
          ) : (
            <Typography.Text type="secondary">{t('cart.summary.delivery_fees_notes')}</Typography.Text>
          )}

          <Divider />

          {shippingFeesAmount && (
            <Space size="large" direction="horizontal">
              <Typography.Text strong>{t('cart.summary.total_price', { count })}</Typography.Text>
              <Typography.Text strong>
                {localizeNumber(totalCheckout)} {globalTranslation('currency')}
              </Typography.Text>
            </Space>
          )}

          {!isCheckout && (
            <Space className="summary-action-buttons">
              <Button onClick={onPayClick} type="primary">
                {t('cart.summary.buttons.pay')}
              </Button>
              <Button onClick={onContinue}>{t('cart.summary.buttons.continue')}</Button>
            </Space>
          )}
        </Space>
      </BorderedSection>
      <RatingModal ref={ratingModalRef} />
    </div>
  );
};

export default OrderSummary;
