import { Form } from 'antd';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Select from 'antd/es/select';
import Typography from 'antd/es/typography';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMountedState } from 'react-use';
import { unwrapResult } from '@reduxjs/toolkit';
import Addresses from 'redux/addresses';
import Map from 'components/molecules/Map';
import forEach from 'lodash/forEach';
import set from 'lodash/set';

import { serialize } from 'object-to-formdata';
import getAddressComponents from 'utils/addressHelper';
import BorderedSection from 'components/organsims/BorderedSection';
import { addAddress, editAddress, fetchById } from 'redux/addresses/thunks';
import { CityCodesList, CityCodesOptions, numberTypeOptions } from './constants';
import entries from 'lodash/entries';
import './index.scss';

const colSpacing = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xxl: 12,
};

const AddressScreen = ({ history }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation('screens');
  const { addressId } = useParams();
  const isMounted = useMountedState();

  const address = useSelector((state) => Addresses.selectors.selectById(state, addressId));

  const [codeVisible, setCodeVisible] = useState(address?.number_type === 'home');
  const initialPoint = address ? { lng: parseFloat(address.lng), lat: parseFloat(address.lat) } : undefined;

  const onValuesChange = (values) => {
    if (values.number_type === 'home') {
      setCodeVisible(true);
    } else if (values.number_type === 'mobile') {
      setCodeVisible(false);
    }
  };

  const prefixSelector = (
    <Form.Item name="city_code" initialValue={CityCodesList[0]} required noStyle>
      <Select options={CityCodesOptions} showSearch showArrow optionFilterProp="label" className="city-code-selector" />
    </Form.Item>
  );

  useEffect(() => {
    if (addressId)
      dispatch(fetchById({ id: addressId }))
        .then(unwrapResult)
        .then(() => {
          form.resetFields();
        })
        .catch(() => {
          history.replace('/profile/addresses');
        });
  }, [addressId, dispatch, form, history]);

  useEffect(() => {
    setCodeVisible(address?.number_type === 'home');
  }, [address?.number_type]);

  const [pinnedLocation, setPinnedLocation] = useState(initialPoint);
  const [formattedAddress, setFormattedAddress] = useState(address?.name);
  const [loading, setLoading] = useState(false);

  const onMapChange = (location) => {
    setPinnedLocation({ lng: location.lng, lat: location.lat });
    setFormattedAddress(location.formatted_address);
    const addressMapped = getAddressComponents(location.address_components);
    forEach(addressMapped, (value, key) => {
      form.setFields([
        {
          name: key,
          value,
        },
      ]);
    });
  };

  const onSuccess = () => {
    setLoading(false);
    Modal.success({
      title: t('address.form.success_add.title'),
      content: t('address.form.success_add.content'),
      okText: t('address.form.success_add.ok'),
      onOk: () => {
        history.goBack();
      },
    });
  };

  const onFailure = (error) => {
    if (error?.address)
      Modal.error({
        title: t('address.form.failure.title'),
        okText: t('address.form.success_add.ok'),
        content: (
          <>
            {error?.address?.map((errorItem) => (
              <Typography.Text>{errorItem}</Typography.Text>
            ))}
          </>
        ),
      });
    else {
      entries(error)?.forEach(([name, errors]) => {
        form.setFields([
          {
            name,
            errors,
          },
        ]);
      });
    }
  };

  const onAddAddress = (address) => {
    dispatch(addAddress(address))
      .then(unwrapResult)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  const onEditAddress = (address) => {
    setLoading(true);
    dispatch(editAddress({ id: addressId, address }))
      .then(unwrapResult)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  const onSubmit = (newAddress) => {
    const newAddressObj = { ...newAddress, ...pinnedLocation, name: formattedAddress };

    let augmentedAddress = {};
    forEach(newAddressObj, (value, key) => {
      set(augmentedAddress, `address[${key}]`, value);
    });
    const address = serialize(augmentedAddress);

    if (pinnedLocation && formattedAddress) {
      if (!addressId) onAddAddress(address);
      else onEditAddress(address);
    } else
      Modal.error({
        title: t('address.form.map_validation.title'),
        content: t('address.form.map_validation.content'),
        okText: t('address.form.map_validation.ok'),
      });
  };

  return (
    <BorderedSection
      title={addressId ? t('address.title.edit') : t('address.title.add')}
      description={t('address.subtitle')}
    >
      <Map onValueChange={onMapChange} initialPoint={initialPoint} />
      <Form
        form={form}
        className="address-form"
        initialValues={address}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
      >
        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.street_validation') }]}
              colon={false}
              name="street_name"
              label={t('address.form.street')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.district_validation') }]}
              colon={false}
              name="district"
              label={t('address.form.district')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.building_validation') }]}
              colon={false}
              name="building"
              label={t('address.form.building')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.apartment_validation') }]}
              colon={false}
              name="apartment"
              label={t('address.form.apartment')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.floor_validation') }]}
              colon={false}
              name="floor"
              label={t('address.form.floor')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.governorate_validation') }]}
              colon={false}
              name="governorate"
              label={t('address.form.governorate')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.number_type_validation') }]}
              colon={false}
              name="number_type"
              label={t('address.form.number_type')}
            >
              <Select className="number-type-selector" options={numberTypeOptions} />
            </Form.Item>
          </Col>
          <Col {...colSpacing}>
            <Form.Item
              rules={[{ required: true, message: t('address.form.phone_validation') }]}
              colon={false}
              name="phone_number"
              label={t('address.form.phone_number')}
              dependencies={['number_type']}
            >
              <Input addonBefore={codeVisible && prefixSelector} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item colon={false} name="instructions" label={t('address.form.instructions')}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="button-wrapper">
            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>
                {addressId ? t('address.edit_button') : t('address.add_button')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BorderedSection>
  );
};

export default AddressScreen;
