import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import BorderedSection from 'components/organsims/BorderedSection';
import PersonalTab from './components/ProfileTabs/Personal';
import AddressesTab from '../../components/organsims/AddressesList';
import FavoritesTab from './components/ProfileTabs/Favorites';
import OrdersTab from './components/ProfileTabs/Orders';
import { useParams } from 'react-router';
import './index.scss';

const Profile = () => {
  const { t } = useTranslation('screens');
  const { tab = 'personal' } = useParams();
  return (
    <BorderedSection title={t('profile.profile_title')}>
      <Tabs type="card" defaultActiveKey={tab}>
        <Tabs.TabPane tab={t('profile.personal.title')} key="personal">
          <PersonalTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('profile.addresses.title')} key="addresses">
          <AddressesTab isEditable />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('profile.favorite.title')} key="favorites">
          <FavoritesTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('profile.orders.title')} key="orders">
          <OrdersTab />
        </Tabs.TabPane>
      </Tabs>
    </BorderedSection>
  );
};
export default Profile;
