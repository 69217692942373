import { Typography, Row, Col, Space, List, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageSettingsSelector } from 'redux/pageSettings/selectors';
import { fetchPageSettings } from 'redux/pageSettings/thunks';
import Logo from 'assets/images/Abdelaziz-stores-logo.jpg';
import { useTranslation } from 'react-i18next';

import { fetchBranches } from 'redux/branches/thunks';
import Branches from 'redux/branches';
import BranchItem from './BranchItem';
import './index.scss';

const listGrid = {
  gutter: 10,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 4,
  xxl: 4,
};

const renderItem = (item) => (
  <List.Item>
    <BranchItem id={item} />
  </List.Item>
);
const AboutUs = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [branchesLoading, setBranchesLoading] = useState(true);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { first_title, second_title, first_paragraph, second_paragraph } =
    useSelector(pageSettingsSelector)('about_us') || {};

  const branches = useSelector(Branches.selectors.selectIds);

  useEffect(() => {
    setPageLoading(true);
    dispatch(fetchPageSettings({ params: { group: 'about_us' } })).then(() => setPageLoading(false));
    dispatch(fetchBranches({ params: { show_all: true } })).then(() => {
      setBranchesLoading(false);
    });
  }, [dispatch, i18n.language]);

  return (
    <div className="about-us-wrapper">
      {pageLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <Space direction="vertical">
          <Row gutter={[40, 20]} align="middle">
            <Col xs={24} sm={24} md={16}>
              <Typography.Title level={2} className="section-title">
                {first_title?.value}
              </Typography.Title>
              <Typography.Text className="section-paragraph">{first_paragraph?.value}</Typography.Text>
              <Typography.Title level={2} className="section-title">
                {second_title?.value}
              </Typography.Title>
              <Typography.Text className="section-paragraph">{second_paragraph?.value}</Typography.Text>
            </Col>
            <Col xs={24} sm={24} md={8} className="image-wrapper">
              <img src={Logo} alt="logo" />
            </Col>
          </Row>
          <List grid={listGrid} dataSource={branches} renderItem={renderItem} loading={branchesLoading} />
        </Space>
      )}
    </div>
  );
};

export default AboutUs;
