import { Col, Row } from 'antd';
import Perks from '../Perks';
import { useTranslation } from 'react-i18next';
import { ReactComponent as perk1 } from 'assets/icons/perk1.svg';
import { ReactComponent as perk2 } from 'assets/icons/perk2.svg';
import { ReactComponent as perk3 } from 'assets/icons/perk3.svg';
import { ReactComponent as perk4 } from 'assets/icons/perk4.svg';
import './index.scss';

const PerksSection = () => {
  const { t } = useTranslation('screens');

  return (
    <div className="perks-section">
      <Row gutter={[15, 15]}>
        {/* <Col xs={12} sm={12} md={6}>
          <Perks image={perk1} title={t('homepage.perk1.title')} description={t('homepage.perk1.description')} />
        </Col> */}
        <Col xs={12} sm={12} md={8}>
          <Perks image={perk2} title={t('homepage.perk2.title')} description={t('homepage.perk2.description')} />
        </Col>
        <Col xs={12} sm={12} md={8}>
          <Perks image={perk3} title={t('homepage.perk3.title')} description={t('homepage.perk3.description')} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Perks image={perk4} title={t('homepage.perk4.title')} description={t('homepage.perk4.description')} />
        </Col>
      </Row>
    </div>
  );
};
export default PerksSection;
