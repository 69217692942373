import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Orders from 'redux/orders';
import { fetchOrders } from 'redux/orders/thunks';
import OrdersList from 'components/organsims/OrdersList';
import EntityPagination from 'components/atoms/Pagination';
import './index.scss';

const OrdersTab = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({ defaultPageSize: 5, total: 100 });
  const [displayedPage, setDisplayPage] = useState(1);

  const ordersMeta = useSelector((state) => state._pagination[`orders/fetchAll`]);
  const userOrders = useSelector(Orders.selectors.selectIds);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchOrders({ params: { per_page: 5, page: displayedPage }, refresh: true })).then(() => {
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }, [dispatch, displayedPage]);

  useEffect(() => {
    setPaginationInfo({ defaultPageSize: ordersMeta?.per_page, total: ordersMeta?.total_entries });
  }, [ordersMeta]);

  return (
    <div className="order-tab">
      <OrdersList orders={userOrders} loading={loading} />
      <EntityPagination
        onChange={setDisplayPage}
        defaultPageSize={paginationInfo.defaultPageSize}
        total={paginationInfo.total}
      />
    </div>
  );
};
export default OrdersTab;
