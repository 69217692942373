import { Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { cartCounter } from 'redux/cart/selectors';
import './index.scss';

const CartIcon = ({ link }) => {
  const { t } = useTranslation('cart-icon');
  const count = useSelector(cartCounter);

  return (
    <Link className="cart-link-button" to={link}>
      <Typography.Text>{t('cart')}</Typography.Text>
      <span className="cart-icon-wrapper">
        <span className="cart-icon-container">
          <ShoppingCartOutlined />
        </span>
        <Tag>{count}</Tag>
      </span>
    </Link>
  );
};

CartIcon.PropType = {
  link: PropTypes.string,
  numberOfItems: PropTypes.number,
};

export default CartIcon;
